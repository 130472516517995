import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

export class ForgotPassword extends Component {
    static displayName = ForgotPassword.name;

    constructor(props) {
        super(props);
        this.state = {
            emailSent: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.Submit = this.Submit.bind(this);
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    async Submit() {
        var postData = {
            email: this.state.email
        };
        await fetch(`/api/Account/ForgotPassword`, {
            method: 'POST',
            credentials: "same-origin",
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        });
        this.setState({ emailSent: true });
    }

    render() {
        return (
            <div>
                <MetaTags>
                    <title>Nutrition Recipe Builder - Forgot Password</title>
                    <meta id="meta-description" name="description" content="Nutrition Recipe Builder" />
                    <meta id="og-title" property="og:title" content="Nutrition Recipe Builder" />
                </MetaTags>
                <div id="content" className="site-content">
                    <div className="content-inner">
                        <div className="container content-container">
                            <div className="row content-row">
                                <div id="primary" className="content-area content-full-width col-12">
                                    <main id="main" className="site-main">
                                        <article id="post-366" className="post-366 page type-page status-publish hentry">
                                            <div className="entry-content clearfix">
                                                <div className="woocommerce"><div className="woocommerce-notices-wrapper"></div>
                                                    {this.state.emailSent &&
                                                        <div className="row">
                                                            <div className="alert alert-info" role="alert">
                                                                Please check your email to reset your password.
                    </div>
                                                        </div>
                                                    }
                                                    <p>Lost your password? Please enter your username or email address. You will receive a link to create a new password via email.</p>
                                                    <p className="woocommerce-form-row woocommerce-form-row--first form-row form-row-first">
                                                        <label for="user_login">Username or email</label>
                                                        <input className="woocommerce-Input woocommerce-Input--text input-text" type="text" autocomplete="username" id="Input.Email" onChange={this.handleChange} name="email" value={this.state.email} />
                                                    </p>
                                                    <div className="clear"></div>
                                                    <p className="woocommerce-form-row form-row">
                                                        <button className="woocommerce-Button button" value="Reset password" onClick={this.Submit}>Reset password</button>
                                                    </p>
                                                </div>
                                            </div>{/* .entry-content */}
                                        </article>{/* #post-366 */}
                                    </main>{/* #main */}
                                </div>{/* #primary */}
                            </div>
                        </div>
                    </div>{/* #content inner */}
                </div>
            </div>
        );
    }
}