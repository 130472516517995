import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

export class Parser extends Component {

    async populateSpiceItUp() {
        await fetch('/api/parse/ParseRecipe/SpiceItUp', { credentials: "same-origin" });
    }

    async populateFoodNetwork() {
        await fetch('/api/parse/ParseRecipe/FoodNetwork', { credentials: "same-origin" });
    }

    async populateAllRecipes() {
        await fetch('/api/parse/ParseRecipe/AllRecipes', { credentials: "same-origin" });
    }

    async UpdateIngredientMap() {
        await fetch('/api/parse/UpdateIngredientMap', { credentials: "same-origin" });
    }


    render() {
        return (<div>
            <MetaTags>
                <title>Parser</title>
                <meta id="meta-description" name="description" content="Recipe Parser" />
                <meta id="og-title" property="og:title" content="Recipe Parser" />
            </MetaTags>
            <h1>Recipe Parser</h1>
            <div className="row">
                <div className="col">
                    <button className="btn btn-primary" onClick={this.populateSpiceItUp}> Import Spice It Up!</button>&nbsp;
                    <button className="btn btn-primary" onClick={this.populateAllRecipes}> Import allrecipes.com</button>&nbsp;
                    <button className="btn btn-primary" disabled={true}> Import thekitchn.com</button>&nbsp;
                    <button className="btn btn-primary" disabled={true}> Import delish.com</button>&nbsp;
                    <button className="btn btn-primary" onClick={this.populateFoodNetwork}> foodnetwork.com</button>&nbsp;
                    <button className="btn btn-primary" disabled={true}> food52.com</button>&nbsp;
                    <button className="btn btn-primary" disabled={true}> norecipes.com</button>&nbsp;
                </div>
            </div>           
        </div>);
    }
}