import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import './../assets/css/menu.css';

export class Spinner extends Component {
    static displayName = Spinner.name;

    render() {
        return (<div className="cms-loader" style={{
            height: '100%',
            width: '100%',
            zIndex: '999999',
            transition: 'all 300ms linear 0ms'
        }}>
            <div className="loading-spinner">
                <div className="loading-dot1"></div>
                <div className="loading-dot2"></div>
            </div>
        </div>);

    }
}