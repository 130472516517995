import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { withRouter } from 'react-router-dom';
import { fa, faTrash, faExclamationTriangle, faSpinner, faSpin } from 'font-awesome/css/font-awesome.css';
import RecipeResults from './RecipeResults';

class NutritionSearch extends Component {
    static displayName = NutritionSearch.name;

    constructor(props) {
        super(props);
        this.state = {
            Nutrients: [],
            Measurements: [],
            recipeList: [],
            searchCriteria: [],
            condition: "0",
            searchBy: "1",
            addErrors: [],
            isLoading: false,
            saveMode: false
        };

        this._isMounted = false;

        this.ChangeNutrient = this.ChangeNutrient.bind(this);
        this.Search = this.Search.bind(this);
        this.GetAvailableNutrients = this.GetAvailableNutrients.bind(this);
        this.GetHyperfinds = this.GetHyperfinds.bind(this);
        this.RemoveSearchItem = this.RemoveSearchItem.bind(this);
        this.AddSearchItem = this.AddSearchItem.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.Save = this.Save.bind(this);
        this.Clear = this.Clear.bind(this);
        this.CancelSave = this.CancelSave.bind(this);
        this.ConfirmSave = this.ConfirmSave.bind(this);
        this.selectSearchCriteria = this.selectSearchCriteria.bind(this);
        this.onPageNumberChange = this.onPageNumberChange.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;

        this.GetAvailableNutrients();
        this.GetHyperfinds();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async GetAvailableNutrients() {
        let windowNutrients = window.NutritionSearch_Nutrients;
        if (windowNutrients) {
            this._isMounted && this.setState({ Nutrients: windowNutrients, selectedNutrien: windowNutrients[0] });
            return;
        }

        const response = await fetch('/api/nutrient/GetAvailableNutrients', { credentials: "same-origin" });
        const data = await response.json();
        windowNutrients = data.result;
        this._isMounted && this.setState({ Nutrients: data.result, selectedNutrien: windowNutrients[0] });
    }

    async GetHyperfinds() {
        const response = await fetch('/api/recipe/GetHyperfinds', { credentials: "same-origin" });
        const data = await response.json();
        this._isMounted && this.setState({ Hyperfinds: data.results });
    }

    async ChangeNutrient(e) {
        const event = e;
        this._isMounted && this.setState({ [event.target.name]: event.target.value });
        const response = await fetch(`/api/nutrient/GetAvailableMeasurements/${event.target.value}`, { credentials: "same-origin" });
        const data = await response.json();
        this._isMounted && this.setState({ Measurements: data.result, measurement: data.result[0] });
    }

    GetSearchCriteria() {
        return this.state.searchCriteria;
        //let retVal = window.NutritionSearch_SearchCritera;
        //if (!retVal)
        //    retVal = window.NutritionSearch_SearchCritera = [];

        //return retVal;
    }

    SetSearchCriteria(item) {
        const self = this;
        //window.NutritionSearch_SearchCritera = item;
        this._isMounted && this.setState({ searchCriteria: item }, () => {
            self.Search();
        });        
    }

    

    async Search() {
        var postData = {
            nutritionSearchCriteria: this.GetSearchCriteria()
        };

        if (postData.length === 0) {
            if (!this.AddSearchItem())
                return;
        }
        this._isMounted && this.setState({ isLoading: true });
        const response = await fetch(`/api/Recipe/GetAllRecipies`, {
            method: 'POST',
            credentials: "same-origin",
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        });

        const data = await response.json();             
        this._isMounted && this.setState({ isLoading: false, recipeList: data.results, totalPages: data.totalPages });
    }

    RemoveSearchItem(index) {
        let data = this.GetSearchCriteria();
        data.splice(index, 1);
        this.SetSearchCriteria(data);
        //window.NutritionSearch_RecipeList = [];        
        //this.setState({ flipScreen: !this.state.flipScreen });
    }

    AddSearchItem() {
        let data = this.GetSearchCriteria();
        let errors = [];
        const state = this.state;
        window.NutritionSearch_RecipeList = [];

        if (!state.selectedNutrient)
            errors.push("Nutrient is a required selection.");

        if (!state.condition)
            errors.push("Condition is a required field.");

        if (!state.amount)
            errors.push("Amount is a required field.");

        if (!state.searchBy)
            errors.push("Search by is a required field.");

        if (!state.measurement)
            errors.push("Measurement is a required field.");

        this._isMounted && this.setState({ addErrors: errors });

        if (errors.length === 0) {
            data.push({
                nutrient: state.selectedNutrient,
                condition: parseInt(state.condition),
                amount: parseFloat(state.amount),
                totalRecipe: (state.searchBy === "0"),
                measurement: state.measurement
            });
            this._isMounted && this.SetSearchCriteria(data);            
            //this.setState({ flipScreen: !this.state.flipScreen });
            return true;
        }
        else
            return false;
    }

    handleChange = (e) => {
        this._isMounted && this.setState({
            [e.target.name]: e.target.value
        });
    }

    Clear() {
        //this.SetSearchCriteria([]);        
        this._isMounted && this.setState({
            addErrors: [], recipeList: [], searchCriteria: []
        });
    }

    Save(e) {
        this._isMounted && this.setState({ saveMode: true });
    }

    CancelSave(e) {
        this._isMounted && this.setState({ saveMode: false });
    }

    async ConfirmSave(e) {
        var postData = {
            name: this.state.hyperfind,
            isGlobal: this.state.isGlobal === "on" ? true : false,
            criteria: this.GetSearchCriteria()
        };

        this._isMounted && this.setState({ isLoading: true });
        const response = await fetch(`/api/Recipe/saveHyperfind`, {
            method: 'POST',
            credentials: "same-origin",
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        });

        await response.json();
        this._isMounted && this.setState({ isLoading: false, saveMode: false });
    }
    selectSearchCriteria(event) {
        const val = event.target.value;
        const self = this;
        this._isMounted && this.setState({ isLoading: true }, () => {
            if (val === "0")
                self.SetSearchCriteria([]);
            else {
                const hyperFind = self.state.Hyperfinds.find(u => u.name === val);
                self.SetSearchCriteria(JSON.parse(JSON.stringify(hyperFind.criteria)));
            }

            self._isMounted && self.setState({ isLoading: false });
        });
    }

    async onPageNumberChange(pageNumber) {
        await this.Search();
    }

    render() {
        const isAdmin = this.props.roles.includes("Admin");

        return (
            <div className="content-inner">
                <MetaTags>
                    <title>Nutrition Recipe Builder - Nutrition Search</title>
                    <meta id="meta-description" name="description" content="Nutrition Search" />
                    <meta id="og-title" property="og:title" content="Nutrition Search" />
                </MetaTags>
                <div className="container content-container">
                    <div className="row content-row">
                        <div id="primary" className="content-area content-has-sidebar float-right col-xl-9 col-lg-8 col-md-12">
                            <RecipeResults isAdmin={isAdmin} results={this.state.recipeList} onPageNumberChange={this.onPageNumberChange} totalPages={this.state.totalPages}>
                                {/*<div className="archive-banner archive-banner-top bg-image" style={{ backgroundImage: 'url(/images/recipe-banner-top.jpg)' }}>
                                        <div className="archive-inner">
                                            <h2>Nutrition Search</h2>
                                        {this.props.isLoggedIn &&
                                            <p><Link to="/account/login">Login</Link> or <Link to="/account/Register">create an account</Link> to save nutritional searches</p>
                                        }
                                        </div>
                                    </div>*/}

                                <article id="post-140" className="post-140 page type-page status-publish hentry">
                                    <div className="entry-content clearfix">
                                        <div className="vc_row wpb_row vc_row-fluid bg-image-ps-inherit"><div className="wpb_column vc_column_container vc_col-sm-12"><div className="vc_column-inner vc_custom_1538532438334"><div className="wpb_wrapper"><div className="cms-banner-layout1 content-position-left  ">
                                            <div className="archive-banner bg-image" style={{ backgroundImage: 'url(/images/banner-faq.jpg)'}}>
                                                <div className="archive-inner">
                                                    <h2>Nutrition Search</h2>
                                                    {!this.props.isLoggedIn &&
                                                        <p><Link to="/account/login">Login</Link> or <Link to="/account/Register">create an account</Link> to save nutritional searches</p>
                                                    }
                                                    <h4 style={{ paddingTop: '10px' }}>Search Criteria</h4>
                                                    <div className="cms-banner-description" style={{ overflowY: 'auto', overflowX: 'none', height: '100px', marginBottom: '0px'}}>
                                                        {this.state.searchCriteria.map((item, index) => {
                                                            return (<div className="row"><div className="col">{item.nutrient} {item.condition === 1 ? "greater than or equal to" : "less than or equal to"} {item.amount} {item.measurement} (Search {item.totalRecipe === true ? "total recipe" : "portion"}) <button className="btn btn-link btn-sm" onClick={() => { this.RemoveSearchItem(index); }}><i className="fa fa-trash"></i></button></div></div>);
                                                        })}
                                                    </div>
                                                    <div className="cms-banner-button">
                                                    </div>
                                                </div>
                                            </div>
                                        </div></div></div></div></div><div className="vc_row wpb_row vc_row-fluid bg-image-ps-inherit"><div className="wpb_column vc_column_container vc_col-sm-12"><div className="vc_column-inner vc_custom_1538533839371"><div className="wpb_wrapper">
                                        </div></div></div></div>                                        
                                    </div>{/* .entry-content */}

    </article>
                                </RecipeResults>
                            </div>
                            <aside id="secondary" className="widget-area widget-has-sidebar sidebar-fixed col-xl-3 col-lg-4 col-md-12">
                                <div className="sidebar-sticky">
                                    <section className="widget widget_cms_recipe_attribute">
                                        <div className="widget-content">                                            
                                        {this.state.addErrors && this.state.addErrors.length > 0 && this.state.addErrors.map((item, index) => {
                                            return (<div className="row">
                                                <div className="col"><div className="alert alert-danger" role="alert">
                                                    <i className="fa fa-exclamation-triangle"></i> {item}
                                                </div></div></div>);
                                        })}
                                                    
                                        {this.props.isLoggedIn && <label><h2>Saved Search</h2> <select className="form-control" onChange={this.selectSearchCriteria} >
                                            <option value="0">(Select One)</option>
                                            {this.state.Hyperfinds && this.state.Hyperfinds.map((item, index) => {
                                                return (<option key={item.name} value={item.name}>{item.name}</option>);
                                            })}
                                        </select></label>}
                                                        
                                                    <h2>Criteria</h2>
                                                    <div className="row">
                                                        <div className={(this.state.Measurements && this.state.Measurements.length > 1) ? "col-md-8" : "col-md-12"}>
                                                            <select className="form-control" name="selectedNutrient" onChange={this.ChangeNutrient} value={this.state.selectedNutrient}>
                                                                {this.state.Nutrients && this.state.Nutrients.map((item, index) => {
                                                                    return (<option value={item} key={item}>{item}</option>);
                                                                })}
                                                            </select>
                                                        </div>
                                                        {this.state.Measurements && this.state.Measurements.length > 1 &&
                                                            <div className="col-md-4">
                                                                <select className="form-control" name="measurement" onChange={this.handleChange} value={this.state.measurement}>
                                                                    {this.state.Measurements && this.state.Measurements.map((item, index) => {
                                                                        return (<option value={item} key={item}>{item}</option>);
                                                                    })}
                                                                </select>
                                                            </div>
                                            }
                                        </div>
                                        <div className="row">
                                                        <div className="col-md-4">
                                                            <select className="form-control" name="condition" onChange={this.handleChange} value={this.state.condition}>
                                                                <option value="0">&lt;=</option>
                                                                <option value="1">&gt;=</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <input className="form-control" type="number" name="amount" placeholder="Amount..." onChange={this.handleChange} value={this.state.amount} />
                                            </div>
                                        </div>                                        
                                        <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="btn-group" role="group" aria-label="Recipe Size">
                                                                <label className="radio-inline"><input type="radio" radioGroup="searchBy" name="searchBy" checked={this.state.searchBy === "0"} value="0" onChange={this.handleChange} /> Total Recipe</label>&nbsp;&nbsp;
                                <label className="radio-inline"><input type="radio" radioGroup="searchBy" name="searchBy" checked={this.state.searchBy === "1"} value="1" onChange={this.handleChange} /> Portion Size</label>
                                                            </div>
                                                </div>
                                                
                                        
                                                        <div className="col-md-12">
                                                            <button className="btn btn-sm" onClick={this.AddSearchItem}>Add Criteria</button>                                                        
                                                                {!this.state.saveMode &&
                                                                    <Fragment>                                                                        
                                    <button className="btn btn-sm" onClick={this.Clear}>Clear</button>&nbsp;
                                    {this.props.isLoggedIn &&
                                                                            <button className="btn btn-sm" onClick={this.Save}>Save</button>
                                                                        }
                                                                    </Fragment>
                                                                }
                                                                {this.state.saveMode &&
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col">
                                                                                    <label>Name <input type="text" className="form-control" name="hyperfind" onChange={this.handleChange} /></label>
                                                                                    {isAdmin &&
                                                                                        <label>Is Global <input type="checkbox" className="form-control" name="isGlobal" onChange={this.handleChange} /></label>
                                                                                    }
                                                                                    <button className="btn btn-primary" onClick={this.ConfirmSave} > Save</button>&nbsp;
                                                    <button className="btn btn-sm" onClick={this.CancelSave}> Cancel</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                </div>
                                                
                                                     </div>
                                                </div>                                            
                                    </section>
                                </div>
                            </aside>
                            {/*!this.state.isLoading &&
                                <ol className="list-unstyled row">
                                    {this.GetRecipeListCache().map((item, index) => {
                                        if (isAdmin)
                                            return (<li className="col-md-4" key={item.id + (item.source ? item.source : '')}><Link to={'/recipe/' + item.id}>{item.label}</Link></li>);
                                        else
                                            return (<li className="col-md-4" key={item.id + (item.source ? item.source : '')}><a target="_blank" rel="noopener noreferrer" href={item.source}>{item.label}</a></li>);
                                    })}
                                </ol>
                            */}                        
                    </div>
                </div>
            </div>);
    }
}

export default withRouter(NutritionSearch);