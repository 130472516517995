import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

export class Register extends Component {
    static displayName = Register.name;

    constructor(props) {
        super(props);
        this.state = {
            registrationSuccessful: false,
            errorList: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.RegisterUser = this.RegisterUser.bind(this);
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    async RegisterUser() {
        var postData = {
            email: this.state.email,
            password: this.state.password,
            confirmPassword: this.state.confirmPassword,
            firstName: this.state.firstName,
            lastName: this.state.lastName
        };
        const response = await fetch(`/api/Account/Register`, {
            method: 'POST',
            credentials: "same-origin",
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        });
        const data = await response.json();
        if (data === true)
            this.setState({ registrationSuccessful: true, errorList: [] });
        else if (data.length > 0)
            this.setState({ registrationSuccessful: false, errorList: data });
    }

    render() {
        return (
            <div>
                <MetaTags>
                    <title>Nutrition Recipe Builder - Register</title>
                    <meta id="meta-description" name="description" content="Nutrition Recipe Builder" />
                    <meta id="og-title" property="og:title" content="Nutrition Recipe Builder" />
                </MetaTags>
                {this.state.errorList && this.state.errorList.map((item, index) => {
                    return (<div className="alert alert-danger" role="alert" key={item.code}>
                        {item.description}
                    </div>);
                })}
                <div className="row">
                    {this.state.registrationSuccessful &&
                        <Fragment>
                            <div className="col-md-12">
                                <div className="alert alert-info" role="alert">
                                    A registration email has been sent.  Please click the link in the email to confirm your registration.
                        </div>
                            </div>
                            <div className="col-md-12">
                                <Link to="/account/login">Login</Link>
                            </div>
                        </Fragment>
                    }
                    {!this.state.registrationSuccessful &&
                        <Fragment>
                            <div className="content-inner">
                                <div className="container content-container">
                                    <div className="row content-row">
                                        <div id="primary" className="content-area content-full-width col-12">
                                            <main id="main" className="site-main">

                                                <article id="post-351" className="post-351 page type-page status-publish hentry">
                                                    <div className="entry-content clearfix">
                                                        <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-parallax="1" data-vc-parallax-image="https://demo.cmssuperheroes.com/themeforest/raworganic/wp-content/uploads/2018/07/parallax1.jpg" className="vc_row wpb_row vc_row-fluid vc_row-has-fill vc_general vc_parallax vc_parallax-content-moving bg-image-ps-inherit" style={{ position: 'relative', left: '-61.6667px', boxSizing: 'border-box', width: '1663px', paddingLeft: '45px', paddingRight: '45px' }}> <div className="wpb_column vc_column_container vc_col-sm-12"><div className="vc_column-inner vc_custom_1532503843284"><div className="wpb_wrapper">
                                                            <div className="wpb_single_image wpb_content_element vc_align_center  vc_custom_1532513090789 inherit">

                                                                <figure className="wpb_wrapper vc_figure">
                                                                    <div className="vc_single_image-wrapper default  vc_box_border_grey">
                                                                        <img width="55" height="55" src="https://demo.cmssuperheroes.com/themeforest/raworganic/wp-content/uploads/2018/07/logo_only.png" className="vc_single_image-img attachment-full wp-post-image" alt="" />
                                                                    </div>
                                                                </figure>
                                                            </div>

                                                            <div className="user-press user-press-default">
                                                                <div className="user-press-header">
                                                                    <h3>Log in to Your Account</h3>
                                                                </div>
                                                                <div className="user-press-body user-press-login">
                                                                    <div className="login-form">
                                                                        <div className="fields-content">
                                                                            <div className="field-group">
                                                                                <input id="user" type="text" className="input user_name" placeholder="Email" data-validate="Required Field" id="Input.Email" name="email" onChange={this.handleChange} value={this.state.email} />
                                                                                <i className="zmdi zmdi-account"></i>
                                                                            </div>
                                                                            <div className="field-group">
                                                                                <input id="user" type="text" className="input user_name" placeholder="First Name" data-validate="Required Field" id="Input.firstName" name="firstName" onChange={this.handleChange} value={this.state.firstName} />
                                                                                <i className="zmdi zmdi-account"></i>
                                                                            </div>
                                                                            <div className="field-group">
                                                                                <input id="user" type="text" className="input user_name" placeholder="Last Name" data-validate="Required Field" id="Input.lastName" name="lastName" onChange={this.handleChange} value={this.state.lastName} />
                                                                                <i className="zmdi zmdi-account"></i>
                                                                            </div>
                                                                            <div className="field-group">
                                                                                <input id="pass" type="password" className="input password" placeholder="Password" data-validate="Required Field" id="Input.Password" name="password" onChange={this.handleChange} value={this.state.password} />
                                                                                <i className="zmdi zmdi-lock-outline"></i>
                                                                            </div>
                                                                            <div className="field-group">
                                                                                <input id="pass" type="password" className="input password" placeholder="Confirm Password" data-validate="Required Field" id="Input.ConfirmPassword" name="confirmPassword" onChange={this.handleChange} value={this.state.confirmPassword} />
                                                                                <i className="zmdi zmdi-lock-outline"></i>
                                                                            </div>
                                                                            <div className="field-group">
                                                                                <button type="button" className="button button-login" onClick={this.RegisterUser}>Register</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                            <div className="vc_parallax-inner skrollable skrollable-between" data-bottom-top="top: 0%;" data-top-bottom="top: 0%;" style={{ height: '100%', backgroundImage: 'url(&quot;https://demo.cmssuperheroes.com/themeforest/raworganic/wp-content/uploads/2018/07/parallax1.jpg&quot;)', top: '0%' }}></div></div><div className="vc_row-full-width vc_clearfix"></div>
                                                        </div>
                                                    </div>{/* .entry-content */}

                                                </article>{/* #post-351 */}
                                            </main>{/* #main */}
                                        </div>{/* #primary */}


                                    </div>
                                </div>
                            </div>
                            {/*<div className="col-md-4">
                                <h4>Register New User</h4>
                                <hr />
                                <div asp-validation-summary="All" className="text-danger"></div>
                                <div className="form-group">
                                    <label asp-for="Input.Email">Email</label>
                                    <input asp-for="Input.Email" className="form-control" name="email" onChange={this.handleChange} value={this.state.name} />
                                    <span asp-validation-for="Input.Email" className="text-danger"></span>
                                </div>

                                <div className="form-group">
                                    <label asp-for="Input.firstName">First Name</label>
                                    <input asp-for="Input.firstName" className="form-control" name="firstName" onChange={this.handleChange} value={this.state.firstName} />
                                    <span asp-validation-for="Input.firstName" className="text-danger"></span>
                                </div>
                                <div className="form-group">
                                    <label asp-for="Input.lastName">Last Name</label>
                                    <input asp-for="Input.lastName" className="form-control" name="lastName" onChange={this.handleChange} value={this.state.lastName} />
                                    <span asp-validation-for="Input.lastName" className="text-danger"></span>
                                </div>
                                <div className="form-group">
                                    <label asp-for="Input.Password">Password</label>
                                    <input asp-for="Input.Password" className="form-control" name="password" type="password" onChange={this.handleChange} value={this.state.password} />
                                    <span asp-validation-for="Input.Password" className="text-danger"></span>
                                </div>
                                <div className="form-group">
                                    <label asp-for="Input.ConfirmPassword">Confirm Password</label>
                                    <input asp-for="Input.ConfirmPassword" className="form-control" name="confirmPassword" type="password" onChange={this.handleChange} value={this.state.confirmPassword} />
                                    <span asp-validation-for="Input.ConfirmPassword" className="text-danger"></span>
                                </div>
                                <button onClick={this.RegisterUser} className="btn btn-primary">Register</button>
                            </div>
                            <div className="col-md-6 col-md-offset-2">
                                <section>
                                    {/*<h4>Use another service to register.</h4>
                            <hr />
           
                                <div>
                                    <p>
                                        There are no external authentication services configured. See <a href="https://go.microsoft.com/fwlink/?LinkID=532715">this article</a> for details on setting up this ASP.NET application to support logging in via external services.
                        </p>
                                </div>
                            <form id="external-account" asp-page="./ExternalLogin" asp-route-returnUrl="@Model.ReturnUrl" method="post" className="form-horizontal">
                                    <div>
                                        <p>
                                            @foreach (var provider in Model.ExternalLogins)
                                {
                                                <button type="submit" className="btn btn-primary" name="provider" value="@provider.Name" title="Log in using your @provider.DisplayName account">@provider.DisplayName</button>
                                            }
                                        </p>
                                    </div>
                                </form>

                                </section>
                            </div>*/}
                        </Fragment>
                    }
                </div>
            </div>
        );
    }
}