import React, { Component, Fragment } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import Home from './components/Home';
import NutiritionSearch from './components/NutiritionSearch';
import Recipe from './components/Recipe';
import { Parser } from './components/Parser';
import { IngredientMap } from './components/IngredientMap';
import { MeasurementMap } from './components/MeasurementMap';
import Login from './components/Account/Login';
import {Logout} from './components/Account/Logout';
import { ForgotPassword } from './components/Account/ForgotPassword';
import { Register } from './components/Account/Register';
import { ResendEmailConfirmation } from './components/Account/ResendEmailConfirmation';
import { ResetPassword } from './components/Account/ResetPassword';
import { UserList } from './components/Account/UserList';
import './custom.css'
import { $, jQuery } from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Chat from './components/Chat/Chat';

import './assets/css/print-style.css';
import './assets/css/theme.css';
import 'bootstrap/dist/css/bootstrap.css';

// export for others scripts to use
window.$ = $;
window.jQuery = jQuery;

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);

        this.state = {
            isLoggedIn: false,
            roles: []
        };

        this.LogInUser = this.LogInUser.bind(this);
    }

    async componentDidMount() {
        return await this.IsAuthenticated();
    }

    async IsAuthenticated() {
        const response = await fetch('/api/account/IsAuthenticated', {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer' // no-referrer, *client
        });
        if (response.status === 200) {
            const data = await response.json();            
            if (data) {                
                this.LogInUser(true, data.userName, data.roles);
            }
        }
    }

    LogInUser(yesNo, userName, roles, loginCallback) {        
        if (yesNo) {
            if (!roles)
                roles = [];
            this.setState({ isLoggedIn: true, userName: userName, roles: roles }, () => {
                if (loginCallback)
                    loginCallback();
            });
        }
        else
            this.setState({ isLoggedIn: false, userName: undefined, roles: [] }, () => {
                if (loginCallback)
                    loginCallback();
            });
    }

    render() {
        return (
            <Layout isLoggedIn={this.state.isLoggedIn} userName={this.state.userName} roles={this.state.roles}>
                <Route exact path='/' render={(props) => <Home {...props} roles={this.state.roles} />} />
                <Route exact path='/nutrition-search' render={(props) => <NutiritionSearch {...props} roles={this.state.roles} isLoggedIn={this.state.isLoggedIn} />} />
                <Route exact path='/recipe/:recipeName'  render={(props) => <Recipe {...props} roles={this.state.roles} />} />
                {this.state.isLoggedIn &&
                    <Fragment>
                    <Route path='/parser' exact component={Parser} />
                    <Route path='/ingredient-map' exact component={IngredientMap} />
                    <Route path='/measurement-map' exact component={MeasurementMap} />
                    <Route path='/account/user-list' exact component={UserList} />                    
                    <Route path='/chat-app' exact render={(props) => <Chat {...props} userName={this.state.userName} />} />
                </Fragment>
                }
                <Route path='/account/login' exact render={(props) => <Login {...props} logInUser={this.LogInUser} />} />
                <Route path='/account/logout' exact render={(props) => <Logout {...props} logInUser={this.LogInUser} />} />
                <Route path='/account/ForgotPassword' exact component={ForgotPassword} />
                <Route path='/account/ResetPassword' exact component={ResetPassword} />
                <Route path='/account/Register' exact component={Register} />
                <Route path='/account/ResendEmailConfirmation' exact component={ResendEmailConfirmation} />
            </Layout>
        );
    }
}
