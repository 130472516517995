import React, { Component, Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import { withRouter } from 'react-router-dom';
import { fa, faArrowLeft, faExclamationTriangle, faSave, faInfo, faEye } from 'font-awesome/css/font-awesome.css';
import AsyncSelect from 'react-select/async';

class Recipe extends Component {
    static displayName = Recipe.name;

    constructor(props) {
        super(props);
        this.state = {
            recipe: {},
            loading: true,
            showBreakDown: false,
            recipeNotFound: false,
            servingSizeCalculation: 1
        };

        this.showHideNutritionBreakDown = this.showHideNutritionBreakDown.bind(this);
        this.updateNutritionalInformation = this.updateNutritionalInformation.bind(this);
        this.closeClick = this.closeClick.bind(this);
        this.fullRecipeNutrition = this.fullRecipeNutrition.bind(this);
        this.serviceSizeNutrition = this.serviceSizeNutrition.bind(this);
    }

    componentDidMount() {
        this.populateRecipeData();
    }

    closeClick() {
        this.props.history.goBack();
    }

    async updateNutritionalInformation(recipe) {
        const response = await fetch('/api/recipe/UpdateNutritionalInformation/', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
            body: JSON.stringify(this.state.recipe) // body data type must match "Content-Type" header
        });
        const data = await response.json();
        this.setState({ recipe: data, loading: false });
    }

    async populateRecipeData() {
        const recipeName = this.props.match.params.recipeName;

        if (window.recipe && window.recipe[recipeName]) {
            this.setState({ recipe: window.recipe[recipeName], loading: false });
            return;
        }
        
        const response = await fetch(`/api/recipe/get/${recipeName}`, {
            cache: 'force-cache',
            credentials: "same-origin"
        });
        if (response.status === 200) {
            const data = await response.json();
            if (!window.recipe)
                window.recipe = [];
            window.recipe[recipeName] = data;
            this.setState({ recipe: data, loading: false });
        }
        else {
            this.setState({ recipeNotFound: true, loading: false });
        }
    }

    showHideNutritionBreakDown() {
        this.setState({ showBreakDown: !this.state.showBreakDown });
    }

    fullRecipeNutrition() {
        this.setState({ servingSizeCalculation: 1 });
    }

    serviceSizeNutrition() {
        this.setState({ servingSizeCalculation: this.state.recipe.servingCalculation });
    }

    render() {
        let errors = [];        

        if (this.state.loading) {
            return (<p><em>Loading...</em></p>);
        } else {
            if (this.state.recipeNotFound) {
                return (<div>
                    <MetaTags>
                        <title>Recipe Not Found</title>
                        <meta id="meta-description" name="description" content="Recipe Not Found" />
                        <meta id="og-title" property="og:title" content="" />
                        <meta id="og-image" property="og:image" content="" />
                    </MetaTags>
                    <h1>Recipe Not Found</h1>
                </div>);
            }
            else {
                return (
                    <div>
                        <MetaTags>
                            <title>{this.state.recipe.title}</title>
                            <meta id="meta-description" name="description" content={this.state.recipe.title} />
                            <meta id="og-title" property="og:title" content={this.state.recipe.title} />
                            <meta id="og-image" property="og:image" content={this.state.recipe.imageLocation} />
                        </MetaTags>
                        <div className="row">
                            <div className="col-md-6">
                                <h1>{this.state.recipe.title}</h1>
                            </div>
                            <div className="col-md-6 pull-right">
                                {/* I got these buttons from simplesharebuttons.com */}
                                <div id="share-buttons">
                                    {/* Buffer */}
                                    <a href={`https://bufferapp.com/add?url=${window.location.href}&text=${this.state.recipe.title}`} target="_blank" rel="noopener noreferrer">
                                        <img src="https://simplesharebuttons.com/images/somacro/buffer.png" alt="Buffer" />
                                    </a>
                                    {/* Digg */}
                                    <a href={`http://www.digg.com/submit?url=${this.state.recipe.title}`} target="_blank" rel="noopener noreferrer">
                                        <img src="https://simplesharebuttons.com/images/somacro/diggit.png" alt="Digg" />
                                    </a>
                                    {/* Email */}
                                    <a href={`mailto:?Subject=${this.state.recipe.title}&Body=${this.state.recipe.title} ${window.location.href}`}>
                                        <img src="https://simplesharebuttons.com/images/somacro/email.png" alt="Email" />
                                    </a>
                                    {/* Facebook */}
                                    <a href={`http://www.facebook.com/sharer.php?u=${window.location.href}`} target="_blank" rel="noopener noreferrer">
                                        <img src="https://simplesharebuttons.com/images/somacro/facebook.png" alt="Facebook" />
                                    </a>
                                    {/* Google+ */}
                                    <a href={`https://plus.google.com/share?url=${window.location.href}`} target="_blank" rel="noopener noreferrer">
                                        <img src="https://simplesharebuttons.com/images/somacro/google.png" alt="Google" />
                                    </a>
                                    {/* LinkedIn */}
                                    <a href={`http://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`} target="_blank" rel="noopener noreferrer">
                                        <img src="https://simplesharebuttons.com/images/somacro/linkedin.png" alt="LinkedIn" />
                                    </a>
                                    {/* Pinterest
                                    <a href="javascript:;" onClick={() => { javascript:void((function()%7Bvar%20e=document.createElement('script');e.setAttribute('type','text/javascript');e.setAttribute('charset','UTF-8');e.setAttribute('src','http://assets.pinterest.com/js/pinmarklet.js?r='+Math.random()*99999999);document.body.appendChild(e)%7D)()); }} rel="noopener noreferrer">
                                        <img src="https://simplesharebuttons.com/images/somacro/pinterest.png" alt="Pinterest" />
                                    </a> */}
                                    {/* Print */}
                                    <a href="javascript:;" onClick={() => { window.print(); }} rel="noopener noreferrer">
                                        <img src="https://simplesharebuttons.com/images/somacro/print.png" alt="Print" />
                                    </a>
                                    {/* Reddit */}
                                    <a href={`http://reddit.com/submit?url=${window.location.href}&title=${this.state.recipe.title}`} target="_blank" rel="noopener noreferrer">
                                        <img src="https://simplesharebuttons.com/images/somacro/reddit.png" alt="Reddit" />
                                    </a>
                                    {/* StumbleUpon*/}
                                    <a href={`http://www.stumbleupon.com/submit?url=${window.location.href}&title=${this.state.recipe.title}`} target="_blank" rel="noopener noreferrer">
                                        <img src="https://simplesharebuttons.com/images/somacro/stumbleupon.png" alt="StumbleUpon" />
                                    </a>
                                    {/* Tumblr*/}
                                    <a href={`http://www.tumblr.com/share/link?url=${window.location.href}&title=${this.state.recipe.title}`} target="_blank" rel="noopener noreferrer">
                                        <img src="https://simplesharebuttons.com/images/somacro/tumblr.png" alt="Tumblr" />
                                    </a>

                                    {/* Twitter */}
                                    <a href={`https://twitter.com/share?url=${window.location.href}&text=${this.state.recipe.title}&hashtags=${this.state.recipe.title}`} target="_blank" rel="noopener noreferrer">
                                        <img src="https://simplesharebuttons.com/images/somacro/twitter.png" alt="Twitter" />
                                    </a>

                                    {/* VK */}
                                    <a href={`http://vkontakte.ru/share.php?url=${window.location.href}`} target="_blank" rel="noopener noreferrer">
                                        <img src="https://simplesharebuttons.com/images/somacro/vk.png" alt="VK" />
                                    </a>

                                    {/* Yummly */}
                                    <a href={`http://www.yummly.com/urb/verify?url=${window.location.href}&title=${this.state.recipe.title}`} target="_blank" rel="noopener noreferrer">
                                        <img src="https://simplesharebuttons.com/images/somacro/yummly.png" alt="Yummly" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <p>
                            <button className="btn btn-link" onClick={this.closeClick}><i className="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
                        </p>
                        {this.state.recipe.imageLocation && <div class="text-center">
                            <img src={this.state.recipe.imageLocation} className="img-fluid rounded mx-auto d-block" alt={this.state.recipe.title} />
                         </div>}
                        <p>Source: <a href={this.state.recipe.url} target="_blank" rel="noopener noreferrer">{this.state.recipe.source}</a></p>
                        <p>
                            {errors.map((item, index) => (
                                <div className="alert alert-danger" role="alert">{item}</div>
                            ))}
                        </p>
                        <div className="row">
                            {!this.state.showBreakDown && <div className="col">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>Nutrition Info</h3>
                                    </div>
                                    <div className="card-body">
                                        {this.state.recipe.servingText &&
                                            <Fragment>
                                                <div><span className="font-weight-bold">Serving Size:</span> {this.state.recipe.servingText} of recipe</div>
                                                <div className="btn-group" role="group" aria-label="Recipe Size">
                                                    <label className="radio-inline"><input type="radio" onClick={this.fullRecipeNutrition} name="optradio" checked={this.state.servingSizeCalculation === 1} /> Full</label>&nbsp;&nbsp;
                                                <label className="radio-inline"><input type="radio" onClick={this.serviceSizeNutrition} name="optradio" checked={this.state.servingSizeCalculation !== 1} /> Serving Size</label>
                                                </div>
                                            </Fragment>
                                        }
                                        <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Nutrient</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.recipe.totalNutrition && this.state.recipe.totalNutrition.map((item, index) => {
                                                    const amount = Math.round(item.amount * this.state.servingSizeCalculation);
                                                    if (amount && amount !== 0)
                                                        return (<tr key={`${item.nutrient}${amount}`} >
                                                            <td>{item.nutrient}</td>
                                                            <td>{amount} {item.unitName}</td>
                                                        </tr>);
                                                    else
                                                        return null;

                                                })}
                                            </tbody>
                                            </table>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            }
                            <div className="col">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>Ingredients</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th><button className="btn btn-link" onClick={this.showHideNutritionBreakDown}>Show/Hide Nutrition Breakdown</button></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.recipe.ingredients && this.state.recipe.ingredients.map((item, index) => (
                                                    <Fragment key={item.name}>
                                                        <tr style={{ border: "1px solid black" }}>
                                                            <td>
                                                                {this.state.showBreakDown ? <input type="text" className="col-md-1" value={item.measurement.unit} /> : item.measurement.unit}&nbsp;
                                                                {item.measurement.type}&nbsp;
                                                                {item.url && <a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a>}
                                                                {!item.url && <span>{item.name}</span>}
                                                                &nbsp;
                                                                {(!item.fdcId || !item.foodPortionId) &&
                                                                    <Fragment>&nbsp;<span className="text-warning" title="Not enough information to calculation nutrition info"><i className="fa fa-exclamation-triangle"></i></span></Fragment>
                                                                }
                                                            </td>
                                                        </tr>
                                                        {this.state.showBreakDown && (<tr>
                                                            <td colSpan={2}>
                                                                <NutritionInfoDropdown nutritionItems={item.calculatedNutritionItems} nutritionItem={item} updateNutritionalInformation={this.updateNutritionalInformation} recipeId={this.state.recipe.recipeId} />
                                                            </td>
                                                        </tr>)}
                                                    </Fragment>
                                                ))}
                                            </tbody>
                                            </table>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            {!this.state.showBreakDown &&
                                <div className="col">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3>Instructions</h3>
                                        </div>
                                        <div className="card-body">
                                            <ul>
                                                {this.state.recipe.instructions && this.state.recipe.instructions.map((instruction, index) => (
                                                    <li key={instruction.instructionId}>{instruction.description}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                );
            }
        }
    }
}

class NutritionInfoDropdown extends Component {
    measurement = 200;

    constructor(props) {
        super(props);

        this.state = {
            selectedItem: this.props.nutritionItems ? this.props.nutritionItems[0] : null,
            showData: false,
            calculatedNutritionItems: null,
            fdcId: this.props.nutritionItem.fdcId,
            foodPortionId: this.props.nutritionItem.foodPortionId,
            conversion: this.props.nutritionItem.conversion,
            foodPortions: [],
            selectedFoodOption: { fdcId: this.props.nutritionItem.fdcId }

        };

        this.selectNutritionItem = this.selectNutritionItem.bind(this);
        this.selectMeasurement = this.selectMeasurement.bind(this);
        this.showHideNutritionBreakDown = this.showHideNutritionBreakDown.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.getFdcIdDescription = this.getFdcIdDescription.bind(this);
        this.handleConversionInputChange = this.handleConversionInputChange.bind(this);
    }

    showHideNutritionBreakDown() {
        this.setState({ showData: !this.state.showData });
    }

    selectNutritionItem(item) {
        //if (this.props.updateNutritionalInformation)
        //    this.props.updateNutritionalInformation();
    }

    componentDidMount() {
        const fdcId = this.props.nutritionItem.fdcId;
        const unitNumeric = this.props.nutritionItem.measurement.unitNumeric;
        const foodPortionId = this.props.nutritionItem.foodPortionId;
        this.selectNutritionItem({
            target: {
                value: this.props.nutritionItem.selectedFdcId
            }
        });

        if (fdcId && foodPortionId && unitNumeric) {
            this.calculateNutritionItems(fdcId,
                foodPortionId,
                unitNumeric);
        }

        if (fdcId)
            this.getFdcIdDescription(fdcId);

        if (this.props.nutritionItem.fdcId) {
            this.getMeasurements(fdcId);
        }
    }

    async getFdcIdDescription(fdcId) {
        const response = await fetch(`/api/recipe/getFdcDescription/${fdcId}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer' // no-referrer, *client            
        });
        const data = await response.json();
        const selectedFoodOption = this.state.selectedFoodOption;
        selectedFoodOption.description = data.result;
        this.setState({ selectedFoodOption: selectedFoodOption });
    }

    async calculateNutritionItems(fdcId, foodPortionId, quantity, conversionFactor) {
        if (fdcId && foodPortionId && quantity) {
            let url;

            if (conversionFactor === undefined)
                url = `/api/recipe/GetIngredientNutritionInfo/${fdcId}/${foodPortionId}/${quantity}`;
            else
                url = `/api/recipe/GetIngredientNutritionInfo/${fdcId}/${foodPortionId}/${quantity}/${conversionFactor}`;

            const response = await fetch(url, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer' // no-referrer, *client            
            });
            const data = await response.json();
            this.setState({ calculatedNutritionItems: data.result });
        }
    }

    //async getFdcIdentifications(recipe) {
    //    const response = await fetch(`/api/recipe/getFdcIdentifications/${recipe.name}`, {
    //        method: 'GET', // *GET, POST, PUT, DELETE, etc.
    //        mode: 'cors', // no-cors, *cors, same-origin
    //        //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //        credentials: 'same-origin', // include, *same-origin, omit
    //        headers: {
    //            'Content-Type': 'application/json'
    //        },
    //        redirect: 'follow', // manual, *follow, error
    //        referrerPolicy: 'no-referrer' // no-referrer, *client            
    //    });
    //    const data = await response.json();
    //    this.setState({ fdcIdentifications: data, loading: false });
    //}


    async getMeasurements(fdcId) {
        const response = await fetch(`/api/recipe/getMeasurements/${fdcId}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer' // no-referrer, *client            
        });
        const data = await response.json();
        this.setState({ foodPortions: data.result });
    }

    selectMeasurement(item) {
        this.setState({ foodPortionId: item.target.value }, () => {
            this.calculateNutritionItems(this.state.fdcId, this.state.foodPortionId, this.props.nutritionItem.measurement.unitNumeric, this.state.conversion);
        });
    }

    loadOptions(input, callback) {
        if (input === '')
            return callback([]);

        return fetch(`/api/recipe/GetFdcIdentifications/${input}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        })
            .then((response) => {
                return response.json();
            }).then((json) => {

                //let js = [];                
                //for (let i = 0; i < json.result.length; i++) {
                //    js.push({
                //        "label": json.result[i]["description"],
                //        "value": json.result[i]["fdcId"]
                //    });
                //}                
                return callback(json.result);
            }).catch(function (error) {
                console.log("error", error);
            });
    };

    getOptionValue = option => {
        return option.value || option.fdcId;
    };

    getOptionLabel = option => {
        return option.label || option.description;
    };

    handleInputChange(inputValue) {
        this.setState({ inputValue });
        return inputValue;
    }

    handleChange(selectedOption) {
        this.setState({
            fdcId: selectedOption.fdcId,
            selectedFoodOption: selectedOption
        }, () => {
            this.getMeasurements(this.state.fdcId);
            this.calculateNutritionItems(this.state.fdcId, this.state.foodPortionId, this.props.nutritionItem.measurement.unitNumeric);
        });
    }

    handleConversionInputChange(event) {        
        this.setState({ conversion: event.target.value });
    }

    saveChanges() {        
        const url = `/api/recipe/save/${this.props.recipeId}/${this.props.nutritionItem.ingredientId}/${this.state.fdcId}/${this.state.foodPortionId}/${this.state.conversion}`;

        return fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        })
            .then((response) => {
                return response.json();
            }).then((json) => {

                //let js = [];                
                //for (let i = 0; i < json.result.length; i++) {
                //    js.push({
                //        "label": json.result[i]["description"],
                //        "value": json.result[i]["fdcId"]
                //    });
                //}                
                //json.result;
            }).catch(function (error) {
                console.log("error", error);
            });
    }

    render() {

        const foodPortion = this.state.foodPortions ? this.state.foodPortions : [];

        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-6">
                        <AsyncSelect
                            cacheOptions="true"
                            value={this.state.selectedFoodOption}
                            loadOptions={this.loadOptions}
                            getOptionValue={this.getOptionValue}
                            getOptionLabel={this.getOptionLabel}
                            onChange={this.handleChange}
                            defaultOptions
                            onInputChange={this.handleInputChange}
                        />
                    </div>
                    <div className="col-md-1">
                        {this.props.nutritionItem.measurement.unitNumeric}
                    </div>
                    <div className="col-md-2">
                        <select className="form-control" onChange={this.selectMeasurement} disabled={foodPortion.length === 0}>
                            <option>(none)</option>
                            <option value="-1" selected={this.props.nutritionItem.foodPortionId === -1} > gram(s)</option>
                            {foodPortion && foodPortion.map((item) => (
                                <option key={item.portionDescription} value={item.foodPortionId} selected={item.foodPortionId === this.props.nutritionItem.foodPortionId}>{item.portionDescription}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-2">                        
                        <input className="form-control" name="conversion" value={this.state.conversion === 0 ? 1 : this.state.conversion} type="number" step=".1" onChange={this.handleConversionInputChange} title="The conversion factor is used to convert different types to other types.  For example, if the product is looking for a pound and only finds ounces than the conversion factor will by 16 since there are 16 ounces in a pound.  The default value should be 1." />                                                    
                    </div>                    
                    {/*<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="save" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-save fa-w-14 fa-spin fa-lg"><path fill="currentColor" d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM224 416c-35.346 0-64-28.654-64-64 0-35.346 28.654-64 64-64s64 28.654 64 64c0 35.346-28.654 64-64 64zm96-304.52V212c0 6.627-5.373 12-12 12H76c-6.627 0-12-5.373-12-12V108c0-6.627 5.373-12 12-12h228.52c3.183 0 6.235 1.264 8.485 3.515l3.48 3.48A11.996 11.996 0 0 1 320 111.48z" className=""></path></svg>*/}
                    <div className="col-md-1">
                        <div className="btn-group" role="group">
                            {this.state.fdcId && this.state.foodPortionId && foodPortion && foodPortion.length > 0 &&                                
                                    <button className="btn btn-secondary" onClick={this.saveChanges} title="Save Changes"><i className="fa fa-save"></i></button>
                                }
                            {this.state.fdcId && this.state.foodPortionId && foodPortion && foodPortion.length > 0 &&
                                <button onClick={this.showHideNutritionBreakDown} className="btn btn-secondary" title="View/Hide Nutrition Info"><i className="fa fa-eye"></i></button>
                            }
                        </div>                        
                    </div>
                </div>
                {this.state.showData &&
                    <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Nutrient</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.calculatedNutritionItems && this.state.calculatedNutritionItems.map((item, index) => {
                                const amount = Math.round(item.amount);
                                if (amount && amount !== 0)
                                    return (<tr key={`${item.nutrient}${amount}${item.unitName}`} >
                                        <td>{item.nutrient}</td>
                                        <td>{amount} {item.unitName}</td>
                                    </tr>);
                                else
                                    return null;

                            })}
                        </tbody>
                    </table>
                    </div>
                }
            </Fragment>
        );
    }
}

export default withRouter(Recipe);
