import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import { Footer } from './Footer';
import '@wordpress/block-library/build-style/style.css';
import '@woocommerce/block-library/build/style.css';
import './../assets/css/theme.css';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div className="site" id="page">
                <NavMenu isLoggedIn={this.props.isLoggedIn} userName={this.props.userName} roles={this.props.roles} />
                <div className="site-content" id="content">
                    {this.props.children}
                </div>
                <Footer isLoggedIn={this.props.isLoggedIn} userName={this.props.userName} />                
            </div>
        );
    }
}
