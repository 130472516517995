import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

export class ResetPassword extends Component {
    static displayName = ResetPassword.name;

    constructor(props) {
        super(props);
        this.state = {
            passwordChanged: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.Submit = this.Submit.bind(this);
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    async Submit() {        
        const search = this.props.location.search;
        const params = new URLSearchParams(search.slice(1));        
        const code = params.get("code");

        var postData = {
            code: code,
            email: this.state.email,
            password: this.state.password,
            confirmPassword: this.state.confirmPassword
        };
        const response = await fetch(`/api/Account/ResetPassword`, {
            method: 'POST',
            credentials: "same-origin",
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        });
        if (response.status === 200) {
            this.setState({ passwordChanged: true });
        }
    }

    render() {
        return (
            <div>
                <MetaTags>
                    <title>Nutrition Recipe Builder - Reset Password</title>
                    <meta id="meta-description" name="description" content="Nutrition Recipe Builder" />
                    <meta id="og-title" property="og:title" content="Nutrition Recipe Builder" />
                </MetaTags>
                <h4>Reset Password</h4>
                <hr />
                {this.state.passwordChanged &&
                    <div className="row">
                        <div className="alert alert-info" role="alert">
                            Your password has been changed.  Please login <Link to="/account/login">here</Link>.
                    </div>
                    </div>
                }
                <div className="row">
                    <div className="col-md-4">
                        <input asp-for="Input.Code" type="hidden" />
                        <div className="form-group">
                            <label for="Input.Email">Email</label>
                            <input id="Input.Email" className="form-control" name="email" onChange={this.handleChange} />
                        </div>
                        <div className="form-group">
                            <label for="Input.Password">Password</label>
                            <input id="Input.Password" type="password" name="password" className="form-control" onChange={this.handleChange} />
                        </div>
                        <div className="form-group">
                            <label asp-for="Input.ConfirmPassword">Confirm Password</label>
                            <input asp-for="Input.ConfirmPassword" type="password" name="confirmPassword" className="form-control" onChange={this.handleChange} />
                        </div>
                        <button className="btn btn-primary" onClick={this.Submit}> Reset</button>
                    </div>
                </div>
            </div>
        );
    }
}