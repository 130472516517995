import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import { fa, faEdit, faSave, faWindowClose, faInfoCircle, faEye } from 'font-awesome/css/font-awesome.css';
import AsyncSelect from 'react-select/async';

export class IngredientMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ingredientMap: [],
            recipeList: null,
            editItem: null,
            selectedFoodOption: null,
            showEdit: false,
            conversion: 1,
            pageNumber: 1
        };

        this.UpdateIngredientMap = this.UpdateIngredientMap.bind(this);
        this.UpdateAllRecipies = this.UpdateAllRecipies.bind(this);
        this.viewRecipies = this.viewRecipies.bind(this);
        this.closeRecipeList = this.closeRecipeList.bind(this);
        this.editIngredient = this.editIngredient.bind(this);
        this.closeEditItem = this.closeEditItem.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleConversionInputChange = this.handleConversionInputChange.bind(this);
        this.getMeasurements = this.getMeasurements.bind(this);
        this.selectMeasurement = this.selectMeasurement.bind(this);
        this.saveIngredientUpdate = this.saveIngredientUpdate.bind(this);
        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
    }

    pageSize = 30;

    componentDidMount() {
        this.GetIngredientMap();
    }

    async GetIngredientMap() {
        const response = await fetch(`/api/IngredientMap/GetIngredientMap?pageSize=${this.pageSize}&pageNumber=${this.state.pageNumber}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer' // no-referrer, *client 
        });
        const data = await response.json();
        this.setState({ ingredientMap: data });
    }

    async UpdateIngredientMap() {
        await fetch('/api/IngredientMap/UpdateIngredientMap', {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer' // no-referrer, *client
        });
        this.GetIngredientMap();
    }


    async UpdateAllRecipies() {
        await fetch('/api/ingredientMap/UpdateAllRecipies', {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer' // no-referrer, *client
        });
        this.GetIngredientMap();
    }

    async viewRecipies(item) {
        const name = item.name && item.name !== '' ? item.name : 'null';
        const type = item.type && item.type !== '' ? item.type : 'null';
        const response = await fetch(`/api/recipe/GetRecipies/${name}/${type}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer' // no-referrer, *client
        });
        const data = await response.json();
        this.setState({ recipeList: data.result });
    }

    closeRecipeList() {
        this.setState({ recipeList: null });
    }

    editIngredient(item) {
        const oldMeasurement = this.oldMeasurement;
        oldMeasurement.fdcId = item.fdcId;
        oldMeasurement.foodPortionId = item.foodPortionId;
        oldMeasurement.description = item.description;
        oldMeasurement.modifier = item.modifier;
        const selectedOption = {
            description: item.description,
            fdcId: item.fdcId
        };

        this.getMeasurements(item.fdcId);
        this.setState({ editItem: item, showEdit: true, selectedFoodOption: selectedOption }, () => {
            let elmnt = document.getElementById(item.name + item.type);
            elmnt.scrollIntoView();
        });
    }

    closeEditItem() {
        const oldMeasurement = this.oldMeasurement;
        const item = this.state.editItem;
        item.fdcId = oldMeasurement.fdcId;
        item.foodPortionId = oldMeasurement.foodPortionId;
        item.description = oldMeasurement.description;
        item.modifier = oldMeasurement.modifier;
        this.setState({ editItem: item, showEdit: false }, () => {
            let elmnt = document.getElementById(item.name + item.type);
            elmnt.scrollIntoView();
        });
    }

    loadOptions(input, callback) {
        if (input === '')
            return callback([]);

        return fetch(`/api/recipe/GetFdcIdentifications/${input}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer' // no-referrer, *client
        })
            .then((response) => {
                return response.json();
            }).then((json) => {

                //let js = [];                
                //for (let i = 0; i < json.result.length; i++) {
                //    js.push({
                //        "label": json.result[i]["description"],
                //        "value": json.result[i]["fdcId"]
                //    });
                //}                
                return callback(json.result);
            }).catch(function (error) {
                console.log("error", error);
            });
    };

    getOptionValue = option => {
        return option.value || option.fdcId;
    };

    getOptionLabel = option => {
        return option.label || option.description;
    };

    handleInputChange(inputValue) {
        this.setState({ inputValue });
        return inputValue;
    }

    handleConversionInputChange(e) {
        const editItem = this.state.editItem;
        editItem[e.target.name] = e.target.value;

        this.setState({
            editItem: editItem
        });
    }

    handleChange(selectedOption) {
        const editItem = this.state.editItem;
        editItem.description = selectedOption.description;
        editItem.fdcId = selectedOption.fdcId;
        this.setState({
            selectedFoodOption: selectedOption,
            editItem: editItem
        }, () => {
            this.getMeasurements(selectedOption.fdcId);
        });
    }

    oldMeasurement = {}

    selectMeasurement(item) {
        const editItem = this.state.editItem;
        editItem.modifier = item.nativeEvent.target[item.nativeEvent.target.selectedIndex].text;
        editItem.foodPortionId = item.target.value;
        this.setState({ editItem: editItem });
    }

    async saveIngredientUpdate() {
        const editItem = this.state.editItem;

        const name = editItem.name ? editItem.name : 'null';
        const type = editItem.type ? editItem.type : 'null';

        await fetch(`/api/ingredientmap/updateingredient/${name}/${type}/${editItem.foodPortionId}/${editItem.fdcId}/${editItem.conversion}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer' // no-referrer, *client            
        });
        this.setState({ showEdit: false }, () => {
            let elmnt = document.getElementById(editItem.name + editItem.type);
            elmnt.scrollIntoView();
        });
    }

    async getMeasurements(fdcId) {
        const response = await fetch(`/api/recipe/getMeasurements/${fdcId}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer' // no-referrer, *client            
        });
        const data = await response.json();
        this.setState({ foodPortions: data.result });
    }

    prev() {
        const self = this;
        this.setState({ pageNumber: this.state.pageNumber - 1 }, () => {
            self.GetIngredientMap();
        });
    }

    next() {
        const self = this;
        this.setState({ pageNumber: this.state.pageNumber + 1 }, () => {
            self.GetIngredientMap();
        });
    }

    render() {
        const foodPortion = this.state.foodPortions ? this.state.foodPortions : [];

        return (<div>
            <MetaTags>
                <title>Ingredient Map</title>
                <meta id="meta-description" name="description" content="Ingredient Map" />
                <meta id="og-title" property="og:title" content="Ingredient Map" />
            </MetaTags>
            <h1>Ingredient Map</h1>
            <div className="row">
                <div className="col">
                    <button className="btn btn-primary" onClick={this.UpdateIngredientMap}>Update Ingredient Map</button>&nbsp;<button className="btn btn-primary" onClick={this.UpdateAllRecipies}>Update All Recipies</button>
                </div>
            </div>
            <div className="row">
                <div className={(this.state.recipeList || this.state.showEdit) ? "col-md-8" : "col-md-12"}>
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>From Food Item</th>
                                    <th>From Type</th>
                                    <th>FDC Description</th>
                                    <th>Modifier</th>
                                    <th>Conversion Factor</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.ingredientMap && this.state.ingredientMap.map((item, index) => {
                                    return (
                                        <tr key={item.name + item.type} id={item.name + item.type} className={(!item.fdcId || !item.foodPortionId) ? "table-danger" : ""}>
                                            <td>{item.name}</td>
                                            <td>{item.type}</td>
                                            <td>{item.description} {item.fdcId && <span>({item.fdcId})</span>}
                                            </td>
                                            <td>
                                                {item.modifier} {item.foodPortionId && item.foodPortionId !== "-1" && item.foodPortionId !== -1 && <span>({item.foodPortionId})</span>}</td>
                                            <td>{(item.conversion !== 1 && item.conversion !== "1") ? item.conversion : ''}</td>
                                            <td>
                                                <div className="btn-group" role="group">
                                                    <button className="btn btn-secondary" onClick={() => { this.viewRecipies(item); }} title="View Receipes"><i className="fa fa-eye"></i></button>
                                                    <button className="btn btn-secondary" onClick={() => { this.editIngredient(item); }} title="Edit Item"><i className="fa fa-edit"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col">
                            <button onClick={this.prev} disabled={this.state.pageNumber === 1}>Prev</button> Page {this.state.pageNumber} <button onClick={this.next} disabled={this.pageSize !== this.state.ingredientMap.length}> Next</button>
                        </div>
                    </div>
                </div>                
                {(this.state.recipeList || this.state.showEdit) &&
                    <div className="col-md-4">
                        <div className="sticky-top affix" data-spy="affix" data-offset-top="205">
                            {this.state.recipeList &&
                                <div className="card">
                                    <div className="card-body">
                                        <div className="pull-right">
                                            <button type="button" className="close" aria-label="Close" onClick={this.closeRecipeList}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div>
                                            <ul>
                                                {this.state.recipeList && this.state.recipeList.map((item, index) => {
                                                    return (<li key={item.title}><Link to={item.url}>{item.title}</Link></li>);
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.editItem && this.state.showEdit &&
                                <div className="card">
                                    <div className="card-body">
                                        <div className="pull-right">
                                            <button type="button" className="close" aria-label="Close" onClick={this.closeEditItem}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div>{this.state.editItem.name}</div>
                                        <div>
                                            <AsyncSelect
                                                cacheOptions="true"
                                                value={this.state.selectedFoodOption}
                                                loadOptions={this.loadOptions}
                                                getOptionValue={this.getOptionValue}
                                                getOptionLabel={this.getOptionLabel}
                                                onChange={this.handleChange}
                                                defaultOptions
                                                onInputChange={this.handleInputChange}
                                            />
                                        </div>
                                        <div>{this.state.editItem.type}</div>
                                        <div>
                                            <select className="form-control" onChange={this.selectMeasurement}>
                                                <option>(none)</option>
                                                <option value="-1" selected={this.state.editItem.foodPortionId === -1} > gram(s)</option>
                                                {foodPortion && foodPortion.map((item) => (
                                                    <option key={item.portionDescription} value={item.foodPortionId} selected={item.foodPortionId === this.state.editItem.foodPortionId}>{item.portionDescription}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="alert alert-info" role="alert">
                                            <i className="fa fa-info-circle"></i> The conversion factor is used to convert different types to other types.  For example, if the product is looking for a pound and only finds ounces than the conversion factor will by 16 since there are 16 ounces in a pound.  The default value should be 1.
</div>
                                        <div>
                                            <div>Conversion Factor</div>
                                            <input className="form-control" name="conversion" value={this.state.editItem.conversion} type="number" step=".1" onChange={this.handleConversionInputChange} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="pull-right">
                                                <button className="btn btn-primary btn-sm" onClick={this.saveIngredientUpdate}>Save</button>&nbsp;
                        <button className="btn btn-secondary btn-sm" onClick={this.closeEditItem}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                }
            </div>
        </div>
        );
    }
}