import React, { Component } from 'react';
import {
    HubConnectionBuilder,
    HubConnectionState,    
} from '@microsoft/signalr';

class Chat extends Component {
    constructor(props) {
        super(props);

        this.state = {            
            message: '',
            messages: [],
            hubConnection: null,
        };
    }

    componentDidMount = async () => {        
        //const hubConnection = new HubConnection('/chat');

        this.connection = await this.setUpSignalRConnection();

        this.connection.on('sendToAll', (nick, receivedMessage) => {
            const text = `${nick}: ${receivedMessage}`;
            const messages = this.state.messages.concat([text]);
            this.setState({ messages });
        });

        //this.connection.invoke("SendToAll", "Raff", "Test").catch(err => console.error(err));

        //this.setState({ hubConnection, nick }, () => {
        //    this.state.hubConnection
        //        .start()
        //        .then(() => console.log('Connection started!'))
        //        .catch(err => console.log('Error while establishing connection :('));

        //    this.state.hubConnection.on('sendToAll', (nick, receivedMessage) => {
        //        const text = `${nick}: ${receivedMessage}`;
        //        const messages = this.state.messages.concat([text]);
        //        this.setState({ messages });
        //    });
        //});
    }

    setUpSignalRConnection = async () => {
        const connection = new HubConnectionBuilder()
            .withUrl('/chathub')
            .withAutomaticReconnect()
            .build();

        connection.on('Message', (message) => {
            console.log('Message', message);
        });
        
        try {
            await connection.start();
        } catch (err) {
            console.log(err);
        }

        if (connection.state === HubConnectionState.Connected) {
            //connection.invoke('SubscribeToBoard', boardId).catch((err) => {
            //    return console.error(err.toString());
            //});
        }

        return connection;
    };

    sendMessage = () => {
        this.connection
            .invoke('sendToAll', this.props.userName, this.state.message)
            .catch(err => console.error(err));

        this.setState({ message: '' });
    };

    render() {
        if (this.props.userName) {
            return (
                <div>
                    <input
                        type="text"
                        value={this.state.message}
                        onChange={e => this.setState({ message: e.target.value })}
                    />

                    <button onClick={this.sendMessage}>Send</button>

                    <div>
                        {this.state.messages.map((message, index) => (
                            <span style={{ display: 'block' }} key={index}> {message} </span>
                        ))}
                    </div>
                </div>
            );
        }
        else
            return null;
    }
}

export default Chat;