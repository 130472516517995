import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { fa, faEdit, faSave, faWindowClose } from 'font-awesome/css/font-awesome.css';

export class MeasurementMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            measurementMap: null
        };        
    }

    componentDidMount() {
        this.GetMeasurementtMap();
    }

    async GetMeasurementtMap() {
        const response = await fetch('/api/MeasurementMap/GetMeasurementMap', { credentials: "same-origin" });
        const data = await response.json();
        this.setState({ measurementMap: data });
    }


    render() {
        return (<div>
            <MetaTags>
                <title>Measurement Map</title>
                <meta id="meta-description" name="description" content="Measurement Map" />
                <meta id="og-title" property="og:title" content="Measurement Map" />
            </MetaTags>
            <h1>Measurement Map</h1>            
            <div className="table-responsive">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Unit</th>
                        <th>Abbreviation</th>                        
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.measurementMap && this.state.measurementMap.map((item, index) => {
                        return (
                            <tr>
                                <td>{item.description}</td>
                                <td>{item.label}</td>                               
                                <td><i className="fa fa-edit"></i>
                                    {/*&nbsp;<i className="fa fa-save"></i>*/}
                                    {/*&nbsp;<i className="fa fa-window-close"></i>*/}

                                </td>
                            </tr>
                        );
                    })}
                </tbody>
                </table>
                </div>
        </div>);
    }
}