import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Footer extends Component {
    static displayName = Footer.name;

    render() {
        return (<footer id="colophon" className="site-footer footer-layout2 bg-overlay footer-4-column divider">
            <div className="top-footer-newsletter">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <form method="post" action="https://demo.cmssuperheroes.com/themeforest/raworganic/?na=s" className="newsletter">
                                <input type="hidden" name="nlang" value="" />
                                <div className="tnp-field tnp-field-email">
                                    <input className="tnp-email" type="email" name="ne" value="" required="" placeholder="Enter your Email Address to Subscribe to our Newsletter" /></div>
                                <div className="tnp-field tnp-field-button"><input className="tnp-button" type="submit" value="Subscribe for Free" /></div></form>                    </div>
                    </div>
                </div>
            </div>
            {/*
            <div className="top-footer">
                <div className="container">
                    <div className="row">
                        <div className="top-col1 col-xl-3 col-lg-3 col-md-4 col-sm-12">
                            <div className="top-footer-logo">
                                <a href="https://demo.cmssuperheroes.com/themeforest/raworganic/"><img src="https://demo.cmssuperheroes.com/themeforest/raworganic/wp-content/uploads/2018/10/footer-logo.png" alt="Logo Footer" /></a>
                            </div>
                            <section id="nav_menu-2" className="widget widget_nav_menu"><h2 className="footer-widget-title">Navigation</h2><div className="menu-menu-navigation-container"><ul id="menu-menu-navigation" className="menu"><li id="menu-item-35" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-35"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/home/">Homepage</a></li>
                                <li id="menu-item-38" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-38"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/about-us/">About Us</a></li>
                                <li id="menu-item-39" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-39"><a href="#">Delivery &amp; Returns</a></li>
                                <li id="menu-item-33" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-33"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/contact-us/">Contact Us</a></li>
                                <li id="menu-item-1268" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1268"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/my-account/">My Account</a></li>
                            </ul></div></section>                        </div>
                        <div className="top-col2 col-xl-6 col-lg-6 col-md-8 col-sm-12">
                            <form action="https://demo.cmssuperheroes.com/themeforest/raworganic/" className="product-searchform" method="get">
                                <div className="footer-product-search">
                                    <input className="search-input" type="text" name="s" value="" placeholder="Search products" />
                                    <button type="submit">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><defs><style>.cls-1-f{fill:none;stroke:#ababab;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><title>search</title><g><g><circle className="cls-1-f" cx="9" cy="9" r="8"></circle><line className="cls-1-f" x1="19" y1="19" x2="14.65" y2="14.65"></line></g></g></svg>
                                    </button>
                                    <input type="hidden" name="post_type" value="product" />
                                </div>
                            </form>
                            <div className="row">
                                <div className="top-sub-col1 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                    <section id="nav_menu-3" className="widget widget_nav_menu"><h2 className="footer-widget-title">Groceries</h2><div className="menu-menu-groceries-container"><ul id="menu-menu-groceries" className="menu"><li id="menu-item-1271" className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-1271"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/product-category/jams-spreads/">Jams &amp; Spreads</a></li>
                                        <li id="menu-item-1272" className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-1272"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/product-category/baking-cooking/">Baking &amp; Cooking</a></li>
                                        <li id="menu-item-1273" className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-1273"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/product-category/oils-sauces/">Oils &amp; Sauces</a></li>
                                        <li id="menu-item-1274" className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-1274"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/product-category/soya-dairy-free/">Soya &amp; Dairy Free</a></li>
                                        <li id="menu-item-1275" className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-1275"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/product-category/cereals/">Cereals</a></li>
                                        <li id="menu-item-1270" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1270"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/shop/">Shop all Groceries</a></li>
                                    </ul></div></section>                                </div>
                                <div className="top-sub-col2 col-xl-6 col-lg-6 col-md-6 col-sm-122">
                                    <section id="nav_menu-4" className="widget widget_nav_menu"><h2 className="footer-widget-title">Raw Foods</h2><div className="menu-menu-raw-foods-container"><ul id="menu-menu-raw-foods" className="menu"><li id="menu-item-1276" className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-1276"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/product-category/jams-spreads/jams/">Raw Jams</a></li>
                                        <li id="menu-item-1277" className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-1277"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/product-category/cereals/">Raw Cereals</a></li>
                                        <li id="menu-item-1278" className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-1278"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/product-category/pasta/">Raw Pasta</a></li>
                                        <li id="menu-item-1279" className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-1279"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/product-category/honey/">Raw Honey</a></li>
                                        <li id="menu-item-1280" className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-1280"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/product-category/noodles/">Raw Noodles</a></li>
                                        <li id="menu-item-1269" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1269"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/shop/">Shop all Raw Foods</a></li>
                                    </ul></div></section>                                </div>
                            </div>
                        </div>                        
                        <div className="top-col3 col-xl-3 col-lg-3 col-md-12 col-sm-12">
                            <div className="cms-lists-widget widget">
                                <h3 className="el-title">Our Promise To You</h3>
                                <ul className="cms-lists-inner">
                                    <li>Same Product Cheaper? We’ll Match It!</li>
                                    <li>Fresh &amp; Delicious Produce, Everytime</li>
                                    <li>No Hassle Refund Policy</li>
                                    <li>Free Delivery on Orders Over £39</li>
                                </ul>
                            </div>
                            <div className="cms-footer-payment">
                                <a href="#" title="Visa">
                                    <img src="https://demo.cmssuperheroes.com/themeforest/raworganic/wp-content/uploads/2018/10/Visa.png" alt="Visa" />
                                </a>
                                <a href="#" title="MasterCard">
                                    <img src="https://demo.cmssuperheroes.com/themeforest/raworganic/wp-content/uploads/2018/10/Mastercard.png" alt="MasterCard" />
                                </a>
                                <a href="#" title="Maestro">
                                    <img src="https://demo.cmssuperheroes.com/themeforest/raworganic/wp-content/uploads/2018/10/Maestro.png" alt="Maestro" />
                                </a>
                                <a href="#" title="American Express">
                                    <img src="https://demo.cmssuperheroes.com/themeforest/raworganic/wp-content/uploads/2018/10/American-Express.png" alt="American Express" />
                                </a>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
            */}
            <div className="bottom-footer">
                <div className="container">
                    <div className="bf-gap"></div>
                    <div className="row">
                        {/*<div className="bottom-col  text-left-lg text-center">
                            Copyright © 2020. RawOrganic by <a target="_blank" href="https://cmssuperheroes.com/">CMSSuperheroes</a>. All Rights Reserved                </div>*/}
                        <div className="bottom-col text-right-xl text-center">
                            <ul id="footermenu" className="footer-menu cms-menu clearfix"><li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-356">
                                {!this.props.isLoggedIn &&
                                    <Link to="/account/login">Login</Link>
                                }
                                {this.props.isLoggedIn &&
                                    <Link to="/account/logout">Logout {this.props.userName}</Link>
                                }
                            </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-355">
                                    <Link to="/account/Register">Create Account</Link></li>
                                {/*<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-358">
                                    <a href="https://demo.cmssuperheroes.com/themeforest/raworganic/faq/">Help</a></li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-357">
                                    <a href="https://demo.cmssuperheroes.com/themeforest/raworganic/contact-1/">Contact Us</a></li>
                                */}
                            </ul>                    </div>
                    </div>
                </div>
            </div>
            <a href="#" className="scroll-top fixed-bottom"><i className="zmdi zmdi-chevron-up"></i></a>
        </footer>);
    }

}