import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { withRouter } from 'react-router-dom';
import { range } from 'lodash';
import { fa, faSpinner, faSpin, faExclamationTriangle } from 'font-awesome/css/font-awesome.css';
import { Spinner } from './Spinner';

class RecipeResults extends Component {
    static displayName = RecipeResults.name;

    static getDerivedStateFromProps(props, state) {
        const retVal = {};

        retVal.recipeList = props.results;

        if (state.totalPages !== props.totalPages)
            retVal.totalPages = props.totalPages;

        return retVal;
    }

    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            recipeList: [],
            isLoading: false,
            pageNumber: 1,
            totalPages: this.props.totalPages ? this.props.totalPages : 0,
            viewType: 'grid',
            byDiet: []
        };
        
        this.moveToPage = this.moveToPage.bind(this);
        this.changeView = this.changeView.bind(this);        
    }

    pageSize = 12;    

    moveToPage(pageNumber) {
        const self = this;        
        this.setState({ pageNumber: pageNumber }, () => {
            //self.populateRecipeData();
            if (self.props.onPageNumberChange)
                self.props.onPageNumberChange(pageNumber);
        });
    }

    changeView(viewType) {
        this.setState({ viewType: viewType });
    }

    render() {
        const isAdmin = this.props.isAdmin ? this.props.isAdmin : false;

        return (<Fragment><main id="main" className="site-main">
            <div className="recipe-primary">
                {this.props.children}
                <div className="archive-topbar">
                    <div className="archive-topbar-heading">
                        <h2>Recipe Results</h2>
                    </div>
                    <div className="archive-layout">
                        <span className={"layout-grid" + (this.state.viewType === 'grid' ? ' active' : '')} onClick={() => { this.changeView("grid"); }}></span>
                        <span className={"layout-list" + (this.state.viewType === 'list' ? ' active' : '')} onClick={() => { this.changeView("list"); }}></span>
                    </div>
                    <Spinner />
                    {/*<div className="archive-topbar-ordering">
                                                <form className="archive-ordering" method="get">
                                                    <select name="orderby" className="orderby raw-archive-sort" style={{ display: 'none' }}>
                                                        <option value="newness" selected="selected">Sort by Newness</option>
                                                        <option value="oldest">Sort by Oldest</option>
                                                        <option value="name_az">Sort by Name A-Z</option>
                                                        <option value="name_za">Sort by Name Z-A</option>
                                                    </select><div className="nice-select orderby raw-archive-sort" tabindex="0"><span className="current">Sort by Newness</span><ul className="list"><li data-value="newness" className="option selected">Sort by Newness</li><li data-value="oldest" className="option">Sort by Oldest</li><li data-value="name_az" className="option">Sort by Name A-Z</li><li data-value="name_za" className="option">Sort by Name Z-A</li></ul></div>
                                                </form>
                                            </div>*/}

                    {!this.state.isLoading && this.state.totalPages &&
                        <nav className="navigation posts-pagination">
                            <div className="posts-page-links">
                                {range(0, this.state.totalPages).map(value => {
                                    return (<span className={"page-numbers " + ((value + 1) === this.state.pageNumber ? "current" : "")} onClick={() => this.moveToPage(value + 1)}>{value + 1}</span>);
                                })}
                                {/*<span className="page-numbers" onClick={this.prev} disabled={this.state.pageNumber === 1}>1</span>
                                                        <span className="page-numbers" onClick={this.next} disabled={this.pageSize !== this.state.recipeList.length}>2</span>*/}
                            </div>
                        </nav>
                    }                   </div>
                <div className={'recipe-body row ' + (this.state.viewType === 'grid' ? ' recipe-layout-grid' : ' recipe-layout-list')}>
                    {this.state.isLoading &&
                        <p className="text-center"><i className="fa fa-spinner fa-spin"></i></p>
                    }
                    {!this.state.isLoading && this.state.recipeList && this.state.recipeList.map((item, index) => {
                        if (item.label.toLowerCase().indexOf(this.state.searchText.toLowerCase()) > -1) {
                            return (<article key={item.id + (item.source ? item.source : '')} className="recipe-item col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                <div className="recipe-item-inner" style={{ height: '375px' }}>
                                    <div className="recipe-image">
                                        {!isAdmin && <Fragment>
                                            <a className="image-grid" rel="noopener noreferrer" target="_blank" href={item.source}>
                                                {item.image && <img width="680" height="380" src={item.image} className="attachment-raworganic-medium size-raworganic-medium wp-post-image wp-post-image" alt={item.label} rel="preconnect" />}
                                            </a>
                                            <a className="image-list bg-image" target="_blank" rel="noopener noreferrer" href={item.source} alt={item.label} style={{ backgroundImage: `url(${item.image})` }}></a></Fragment>}
                                        {isAdmin && <Fragment>
                                            <Link className="image-list bg-image" target="_blank" rel="noopener noreferrer" to={'/recipe/' + item.id} style={{ backgroundImage: `url(${item.image})` }}>
                                                {item.image && <img width="680" height="380" src={item.image} className="attachment-raworganic-medium size-raworganic-medium wp-post-image wp-post-image" alt={item.label} rel="preconnect" />}
                                            </Link></Fragment>}
                                    </div>
                                    <div className="recipe-holder">
                                        {/*<div className="recipe-meal"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/recipe-meal/desserts/" rel="tag" style={{ color: '#02d693' }}>Desserts</a></div>*/}
                                        <h3 className="recipe-title" style={{ height: '66px' }}>
                                            {isAdmin && <Link to={'/recipe/' + item.id}>{item.label} {!item.active && <span className="text-warning" title="Mapping not complete."><i className="fa fa-exclamation-triangle"></i></span>}</Link>}
                                            {!isAdmin && <a target="_blank" rel="noopener noreferrer" href={item.source}>{item.label}</a>}
                                            {/*<a href="https://demo.cmssuperheroes.com/themeforest/raworganic/recipe/mixed-nut-chocolate-cake-with-smooth-peanut-butter-layer/">Mixed Nut Chocolate Cake with Smooth Peanut Butter Layer</a>*/}
                                        </h3>
                                        <div className="recipe-desc">
                                            {item.description}
                                        </div>
                                        <ul className="recipe-meta">
                                            {/*<li className="recipe-rating"><div className="raw-reviews-star"><div className="raw-stars"><span className="raw-star-show zmdi zmdi-star"></span><span className="raw-star-show zmdi zmdi-star"></span><span className="raw-star-show zmdi zmdi-star"></span><span className="raw-star-show zmdi zmdi-star"></span><span className="raw-star-show zmdi zmdi-star"></span></div></div></li>
                                                                    <li className="recipe-level"><i className="zmdi zmdi-alert-circle-o"></i>Easy</li>*/}
                                            {item.tag && item.tag.servings && <li><i className="zmdi zmdi-account-o"></i>Serving Size: {item.tag.servings}</li>}
                                            {/*<li><i className="zmdi zmdi-time"></i>50 mins</li>*/}
                                        </ul>
                                    </div>
                                </div>
                            </article>);
                        }
                        else
                            return null;

                    })}
                </div>
                <div className="recipe-footer">
                    {!this.state.isLoading && this.state.totalPages &&
                        <nav className="navigation posts-pagination">
                            <div className="posts-page-links">
                                {range(0, this.state.totalPages).map(value => {
                                    return (<span className={"page-numbers " + ((value + 1) === this.state.pageNumber ? "current" : "")} onClick={() => this.moveToPage(value + 1)}>{value + 1}</span>);
                                })}
                                {/*<span className="page-numbers" onClick={this.prev} disabled={this.state.pageNumber === 1}>1</span>
                                                        <span className="page-numbers" onClick={this.next} disabled={this.pageSize !== this.state.recipeList.length}>2</span>*/}
                            </div>
                        </nav>
                    }
                </div>
            </div>
        </main>{/* #main */ }</Fragment>);
    }
}

export default withRouter(RecipeResults);