import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { withRouter } from 'react-router-dom';
import { fa, faExclamationTriangle } from 'font-awesome/css/font-awesome.css';
import 'material-design-iconic-font/dist/css/material-design-iconic-font.css';

class Login extends Component {
    static displayName = Login.name;

    constructor(props) {
        super(props);
        this.state = {};

        this.handleChange = this.handleChange.bind(this);
        this.LoginUser = this.LoginUser.bind(this);
        this.LoginCallback = this.LoginCallback.bind(this);
        //this.BypassLogin = this.BypassLogin.bind(this);
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    async LoginUser() {
        const self = this;
        let postData = {
            email: this.state.email,
            password: this.state.password,
            rememberMe: this.state.rememberMe ? true : false
        };
        const response = await fetch(`/api/Account/Login`, {
            method: 'POST',
            credentials: "same-origin",
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        });
        const data = await response.json();
        if (!data) {
            this.setState({ error: "Invalid login attempt." });
        } else {
            if (self.props.logInUser) {
                self.ResetCache();
                self.props.logInUser(true, postData.email, data.roles, self.LoginCallback);
            }
        }
    }

    LoginCallback() {
        if (this.props && this.props.history)
            this.props.history.push('/');
    }

    ResetCache() {
        window.Home_RecipeList = [];
    }

    render() {
        return (
            <div>
                <MetaTags>
                    <title>Nutrition Recipe Builder - Login</title>
                    <meta id="meta-description" name="description" content="Nutrition Recipe Builder" />
                    <meta id="og-title" property="og:title" content="Nutrition Recipe Builder" />
                </MetaTags>
                <div className="content-inner">
                    <div className="container content-container">
                        <div className="row content-row">
                            <div id="primary" className="content-area content-full-width col-12">
                                <main id="main" className="site-main">

                                    <article id="post-351" className="post-351 page type-page status-publish hentry">
                                        <div className="entry-content clearfix">
                                            <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-parallax="1" data-vc-parallax-image="https://demo.cmssuperheroes.com/themeforest/raworganic/wp-content/uploads/2018/07/parallax1.jpg" className="vc_row wpb_row vc_row-fluid vc_row-has-fill vc_general vc_parallax vc_parallax-content-moving bg-image-ps-inherit" style={{ position: 'relative', left: '-61.6667px', boxSizing: 'border-box', width: '1663px', paddingLeft: '45px', paddingRight: '45px' }}> <div className="wpb_column vc_column_container vc_col-sm-12"><div className="vc_column-inner vc_custom_1532503843284"><div className="wpb_wrapper">
                                                <div className="wpb_single_image wpb_content_element vc_align_center  vc_custom_1532513090789 inherit">

                                                    <figure className="wpb_wrapper vc_figure">
                                                        <div className="vc_single_image-wrapper default  vc_box_border_grey">
                                                            <img width="55" height="55" src="https://demo.cmssuperheroes.com/themeforest/raworganic/wp-content/uploads/2018/07/logo_only.png" className="vc_single_image-img attachment-full wp-post-image" alt="" />
                                                        </div>                                                                                                              
                                                    </figure>
                                                </div>

                                                <div className="user-press user-press-default">
                                                    <div className="user-press-header">
                                                        <h3>Log in to Your Account</h3>
                                                    </div>
                                                    <div className="user-press-body user-press-login">
                                                        <div className="login-form">
                                                            <div className="fields-content">
                                                                <div className="field-group">
                                                                    <input id="user" type="text" className="input user_name" placeholder="Username" data-validate="Required Field" id="Input.Email" name="email" onChange={this.handleChange} value={this.state.email} />
                                                                    <i className="zmdi zmdi-account"></i>
                                                                </div>
                                                                <div className="field-group">
                                                                    <input id="pass" type="password" className="input password" placeholder="Password" data-validate="Required Field" id="Input.Password" name="password" onChange={this.handleChange} value={this.state.password} />
                                                                    <i className="zmdi zmdi-lock-outline"></i>
                                                                </div>
                                                                <div className="form-group">
                                                                    <div className="checkbox">
                                                                        <label htmlFor="Input.RememberMe">
                                                                            <input id="Input.RememberMe" name="rememberMe" type="checkbox" onChange={this.handleChange} value={this.state.rememberMe} /> Remember Me?
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="field-group">
                                                                    <button type="button" className="button button-login" onClick={this.LoginUser}>Log In</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="user-press-footer">
                                                        New to our site? <Link to="/account/Register">Sign up</Link>		</div>
                                                </div>
                                                <div className="user-press-forgot-pass">
                                                    <Link to="/account/ForgotPassword">Forgot your password?</Link>
                                                </div>
                                            </div></div></div><div className="vc_parallax-inner skrollable skrollable-between" data-bottom-top="top: 0%;" data-top-bottom="top: 0%;" style={{ height: '100%', backgroundImage: 'url(&quot;https://demo.cmssuperheroes.com/themeforest/raworganic/wp-content/uploads/2018/07/parallax1.jpg&quot;)', top: '0%' }}></div></div><div className="vc_row-full-width vc_clearfix"></div>
                                            </div>{/* .entry-content */}

    </article>{/* #post-351 */}
                </main>{/* #main */}
                                        </div>{/* #primary */}

            
        </div>
                            </div>
                        </div>

                {/*<div className="row">
                    <div className="col-md-4">
                        <section>
                            <h4>Log In</h4>
                            <hr />
                            {this.state.error && <div className="row">
                                <div className="col"><div className="alert alert-danger" role="alert">
                                    <i className="fa fa-exclamation-triangle"></i> {this.state.error}
                                </div></div></div>
                            }
                            <div asp-validation-summary="All" className="text-danger"></div>
                            <div className="form-group">
                                <label htmlFor="Input.Email">Email</label>
                                <input id="Input.Email" name="email" className="form-control" onChange={this.handleChange} value={this.state.email} />
                                <span htmlFor="Input.Email" className="text-danger"></span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="Input.Password">Password</label>
                                <input id="Input.Password" type="password" name="password" className="form-control" onChange={this.handleChange} value={this.state.password} />
                                <span htmlFor="Input.Password" className="text-danger"></span>
                            </div>
                            <div className="form-group">
                                <div className="checkbox">
                                    <label htmlFor="Input.RememberMe">
                                        <input id="Input.RememberMe" name="rememberMe" type="checkbox" onChange={this.handleChange} value={this.state.rememberMe} /> Remember Me?
                        </label>
                                </div>
                            </div>
                            <div className="form-group">
                                <button onClick={this.LoginUser} className="btn btn-primary">Log in</button>
                            </div>
                            <div className="form-group">
                                <p>
                                    <Link to="/account/ForgotPassword">Forgot your password?</Link>
                                </p>
                                <p>
                                    <Link to="/account/Register">Register as a new user</Link>
                                </p>
                                <p>
                                    <Link to="/account/ResendEmailConfirmation">Resend email confirmation</Link>
                                </p>                                
                            </div>
                        </section>
                    </div>
                    <div className="col-md-6 col-md-offset-2">
                        <section>
                            {/*<h4>Use another service to log in.</h4>
                            <hr />
            
                                <div>
                                    <p>
                                        There are no external authentication services configured. See <a href="https://go.microsoft.com/fwlink/?LinkID=532715" target="_blank">this article</a> for details on setting up this ASP.NET application to support logging in via external services.
                        </p>
                                </div>
            
                            <form id="external-account" asp-page="./ExternalLogin" asp-route-returnUrl="@Model.ReturnUrl" method="post" className="form-horizontal">
                                    <div>
                                        <p>
                                            @foreach (var provider in Model.ExternalLogins)
                                {
                                                <button type="submit" className="btn btn-primary" name="provider" value="@provider.Name" title="Log in using your @provider.DisplayName account">@provider.DisplayName</button>
                                            }
                                        </p>
                                    </div>
                                </form>
                        </section>
                </div>
            </div>*/}
            </div>
        );
    }
}

export default withRouter(Login);