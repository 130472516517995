import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { fa, faPlus, faEdit, faCheckCircle, faRemove, faExclamationTriangle } from 'font-awesome/css/font-awesome.css';

export class UserList extends Component {
    static displayName = UserList.name;

    constructor(props) {
        super(props);
        this.state = {
            editItem: null,
            showEdit: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.GetUserList = this.GetUserList.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.closeEditItem = this.closeEditItem.bind(this);
        this.RemoveRole = this.RemoveRole.bind(this);
        this.AddRole = this.AddRole.bind(this);
    }
    componentDidMount() {
        this.GetUserList();
    }

    async GetUserList() {
        const response = await fetch('/api/account/GetUserList', {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer' // no-referrer, *client 
        });
        if (response.status === 401) {
            this.setState({ unauthorized: true });
            return;
        }
        const data = await response.json();
        this.setState({ userList: data });
    }

    handleChange = (e) => {
        const editItem = this.state.editItem;        
        editItem[e.target.name] = e.target.value;
        this.setState({
            editItem: editItem
        });
    }

    editUser(item) {        
        this.setState({ editItem: item, showEdit: true}, () => {
            let elmnt = document.getElementById(item.email);
            elmnt.scrollIntoView();
        });
    }

    closeEditItem() {        
        const item = this.state.editItem;
        //item.fdcId = oldMeasurement.fdcId;
        //item.foodPortionId = oldMeasurement.foodPortionId;
        //item.description = oldMeasurement.description;
        //item.modifier = oldMeasurement.modifier;
        this.setState({ editItem: item, showEdit: false }, () => {
            let elmnt = document.getElementById(item.email);
            elmnt.scrollIntoView();
        });
    }

    async saveUser() {
        const response = await fetch('/api/account/SaveUser', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client 
            body: JSON.stringify(this.state.editItem)
        });
        if (response.status === 401) {
            this.setState({ unauthorized: true });
            return;
        }
        this.setState({ editItem: null, showEdit: false });
    }

    RemoveRole(index) {        
        const editItem = this.state.editItem;
        editItem.roles.splice(index, 1);
        this.setState({ editItem: editItem });
    }

    AddRole() {
        const editItem = this.state.editItem;
        const role = this.state.editItem.role;        
        if (this.state.editItem.role && editItem.roles.indexOf(role) === -1) {
            editItem.roles.push(role);
            this.setState({ editItem: editItem });
        }
    }

    render() {
        return (
            <div>
                <MetaTags>
                    <title>Nutrition Recipe Builder - User List</title>
                    <meta id="meta-description" name="description" content="Nutrition Recipe Builder" />
                    <meta id="og-title" property="og:title" content="Nutrition Recipe Builder" />
                </MetaTags>
                <h4>User List</h4>
                <hr />
                {this.state.unauthorized && <div className="alert alert-danger" role="alert">
                    You are not authorized to view this page.
                </div>}
                <div className="row">
                    <div className={this.state.showEdit ? "col-md-8" : "col-md-12"}>
                        <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    <th>Locked Out</th>
                                    <th>Roles</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.userList && this.state.userList.map((item, index) => {
                                    return (<tr id={item.email}>
                                        <td>{item.firstName}</td>
                                        <td>{item.lastName}</td>
                                        <td><a href={`mailto:${item.email}`} title={item.email} style={{ textOverflow: "ellipsis"}}>{item.email}</a> {item.emailConfirmed ? <i className="fa fa-check-circle alert-success" title="Confirmed"></i> : <i className="fa fa-exclamation-triangle alert-danger" title="Not Confirmed"></i>}</td>
                                        <td>{item.phoneNumber}</td>
                                        <td>{item.lockedOut}</td>
                                        <td><ul>{item.roles && item.roles.map((item, index) => {
                                            return (<li>{item}</li>);
                                        })}
                                        </ul></td>
                                        <td>
                                            <div className="btn-group" role="group">
                                                <button className="btn btn-secondary btn-sm" title="Edit" onClick={() => { this.editUser(item) }}><i className="fa fa-edit"></i></button>
                                            </div>
                                        </td>
                                    </tr>);
                                })}
                            </tbody>
                            </table>
                        </div>
                    </div>

                    {this.state.showEdit &&
                        <div className="col-md-4">
                            <div className="sticky-top affix" data-spy="affix" data-offset-top="205">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="pull-right">
                                        <button type="button" className="close" aria-label="Close" onClick={this.closeEditItem}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div>
                                        <div>First Name</div>
                                        <input className="form-control" name="firstName" value={this.state.editItem.firstName} onChange={this.handleChange} type="text" />
                                        </div>
                                        <div>
                                            <div>Last Name</div>
                                        <input className="form-control" name="lastName" value={this.state.editItem.lastName} onChange={this.handleChange} type="text" />
                                        </div>
                                        <div>
                                            <div>Email Address</div>
                                        <input className="form-control" name="email" value={this.state.editItem.email} onChange={this.handleChange} type="email" />
                                        </div>
                                        <div>
                                            <div>Phone Number</div>
                                        <input className="form-control" name="phoneNumber" value={this.state.editItem.phoneNumber} onChange={this.handleChange} type="tel" />
                                        </div>
                                        <div>
                                            <label>
                                            <input name="accountLocked" type="checkbox" value={this.state.editItem.accountLocked} onChange={this.handleChange} /> Account Locked
                                        </label>
                                        </div>
                                        <div>
                                        <label>
                                            <input name="emailConfirmed" type="checkbox" value={this.state.editItem.emailConfirmed} onChange={this.handleChange} /> Email Confirmed
                                        </label>
                                        </div>
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <h5>Roles</h5>
                                                    </div>
                                                    <div className="col-md-8">
                                                    <div className="input-group input-group-sm mb-3">
                                                        <select className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" name="role" onChange={this.handleChange}>
                                                                <option value="Admin">Admin</option>
                                                                <option value="Api User">Api User</option>
                                                                <option value="Web Site User">Web Site User</option>
                                                            </select>
                                                            <div className="input-group-append">
                                                            <span className="input-group-text" onClick={this.AddRole} id="inputGroup-sizing-sm"><i className="fa fa-plus"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>                                            
                                            <ul>
                                                {this.state.editItem.roles && this.state.editItem.roles.map((item, index) => {
                                                    return (<li>{item} <button className="btn btn-link" onClick={() => { this.RemoveRole(index); }}><i className="fa fa-remove alert-danger" title="Remove" ></i></button></li>);
                                                })}
                                            </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="pull-right">
                                                <button className="btn btn-primary btn-sm" onClick={this.saveUser}>Save</button>&nbsp;
                        <button className="btn btn-secondary btn-sm" onClick={this.closeEditItem}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                </div>
            </div>
        );
    }
}