import React, { Component, Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';

export class ResendEmailConfirmation extends Component {
    static displayName = ResendEmailConfirmation.name;

    constructor(props) {
        super(props);
        this.state = {
            messageSent: false,
            errorList: [],
            validatedSuccessfully: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.Submit = this.Submit.bind(this);
    }

    async componentDidMount() {        
        const search = this.props.location.search;        
        const params = new URLSearchParams(search.slice(1));
        const userId = params.get("userId");
        const code = params.get("code");
        if (userId && code) {            
            const postData = {
                userId: userId,
                code: code
            };
            const response = await fetch(`/api/Account/ConfirmEmail`, {
                method: 'POST',
                credentials: "same-origin",
                headers: {
                    'Accept': 'application/json, text/plain',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postData)
            });
            const data = await response.json();
            if (!data.succeeded) {
                this.setState({ errorList: data.errors });
            } else {
                this.setState({ validatedSuccessfully: true, errorList: [] });
            }            
        }
        
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    async Submit() {
        var postData = {
            email: this.state.email
        };
        const response = await fetch(`/api/Account/ResendEmailConfirmation`, {
            method: 'POST',
            credentials: "same-origin",
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        });
        const data = await response.json();        
        if (!data.succeeded) {
            this.setState({ errorList: data.errors });
        }
    }

    render() {
        return (
            <div>
                <MetaTags>
                    <title>Nutrition Recipe Builder - Resend Email Confirmation</title>
                    <meta id="meta-description" name="description" content="Nutrition Recipe Builder" />
                    <meta id="og-title" property="og:title" content="Nutrition Recipe Builder" />
                </MetaTags>
                {this.state.errorList && this.state.errorList.map((item, index) => {
                    return (<div className="alert alert-danger" role="alert">
                        {item.description}
                    </div>);
                })}
                {this.state.validatedSuccessfully && <div className="alert alert-info" role="alert">
                    User validated successfully!  <Link to="/account/login">Please login here</Link>
                    </div>}
                {!this.state.messageSent &&
                    <Fragment>
                        <h4>Resend Email Confirmation</h4>
                        <hr />
                        <div className="row">
                            <div className="col-md-4">
                                <div asp-validation-summary="All" className="text-danger"></div>
                                <div className="form-group">
                                    <label for="Input.Email">Email</label>
                                    <input id="Input.Email" className="form-control" name="email" onChange={this.handleChange} value={this.state.email} />
                                    <span asp-validation-for="Input.Email" className="text-danger"></span>
                                </div>
                                <button className="btn btn-primary" onClick={this.Submit}> Resend</button>
                            </div>
                        </div>
                    </Fragment>
                }
                {this.state.messageSent &&
                    <Fragment>
                        <div className="alert alert-info" role="alert">
                            Email sent successfully!  Please check your inbox.
                    </div>
                    </Fragment>
                }
            </div>
        );
    }
}