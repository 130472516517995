import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { withRouter } from 'react-router-dom';

export class Logout extends Component {
    static displayName = Logout.name;


    async componentDidMount() {
        this.ResetCache();
        
        await fetch(`/api/Account/Logout`, {
            method: 'POST',
            credentials: "same-origin",
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json'
            }
        });

        if (this.props.logInUser)
            this.props.logInUser(false);
    }

    ResetCache() {
        window.Home_RecipeList = [];
    }

    render() {
        return (
            <div>
                <MetaTags>
                    <title>Nutrition Recipe Builder - Logout</title>
                    <meta id="meta-description" name="description" content="Nutrition Recipe Builder" />
                    <meta id="og-title" property="og:title" content="Nutrition Recipe Builder" />
                </MetaTags>
                <div className="row">
                    <div className="col-md-4">
                        <section>
                            <h4>You have been logged out.</h4>
                            <hr />
                        </section>
                    </div>
                    <div className="col-md-6 col-md-offset-2">
                        <section>
                            {/*<h4>Use another service to log in.</h4>
                            <hr />
            
                                <div>
                                    <p>
                                        There are no external authentication services configured. See <a href="https://go.microsoft.com/fwlink/?LinkID=532715" target="_blank">this article</a> for details on setting up this ASP.NET application to support logging in via external services.
                        </p>
                                </div>
            
                            <form id="external-account" asp-page="./ExternalLogin" asp-route-returnUrl="@Model.ReturnUrl" method="post" className="form-horizontal">
                                    <div>
                                        <p>
                                            @foreach (var provider in Model.ExternalLogins)
                                {
                                                <button type="submit" className="btn btn-primary" name="provider" value="@provider.Name" title="Log in using your @provider.DisplayName account">@provider.DisplayName</button>
                                            }
                                        </p>
                                    </div>
                                </form>*/}
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}