import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import './../assets/css/menu.css';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.state = {
            collapsed: true
        };

        this.toggleNavbar = this.toggleNavbar.bind(this);
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        const isAdmin = this.props.roles.includes("Admin");

        return (
            <header id="masthead" className="site-header">
                <div id="site-header-wrap" className="header-layout1 fixed-height is-sticky" style={{ height: '100px' }}>
                    <div className="site-header-top">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ul id="topmenu" className="top-menu cms-menu clearfix">
                                        <li id="menu-item-356" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-356">
                                            {!this.props.isLoggedIn &&
                                                <Link to="/account/login">Login</Link>
                                            }
                                            {this.props.isLoggedIn &&
                                                <Link to="/account/logout">Logout {this.props.userName}</Link>
                                            }
                                        </li>
                                        {!this.props.isLoggedIn &&
                                            <li id="menu-item-355" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-355">
                                            <Link to="/account/Register">Create Account</Link>                                            
                                        </li>
                                        }
                                        {/*<li id="menu-item-358" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-358"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/faq/">Help</a></li>
                                        <li id="menu-item-357" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-357"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/contact-1/">Contact Us</a></li>*/}
                                    </ul>                        </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="site-header-middle">
                        <div className="container">
                            <div className="row">
                                <div className="site-branding">
                                    <a className="logo" href="https://demo.cmssuperheroes.com/themeforest/raworganic/" title="RawOrganic" rel="home"><img src="https://demo.cmssuperheroes.com/themeforest/raworganic/wp-content/themes/raworganic/assets/images/logo-dark.png" alt="RawOrganic" /></a>
                                </div>
                                
                                <div className="topbar-product-search">
                                    <form action="https://demo.cmssuperheroes.com/themeforest/raworganic/" className="product-searchform" method="get">
                                        <div className="product-search-category">
                                            <select name="product_cat" style={{ display: 'none' }}>
                                                <option value="">Browse Category</option>
                                                <option value="soya-dairy-free">Soya &amp; Dairy Free</option><option value="baking-cooking">Baking &amp; Cooking</option><option value="butter">Butter</option><option value="cereals">Cereals</option><option value="chocolate-spread">Chocolate Spread</option><option value="dried-fruit">Dried Fruit</option><option value="honey">Honey</option><option value="jams">Jams</option><option value="jams-spreads">Jams &amp; Spreads</option><option value="noodles">Noodles</option><option value="oils-sauces">Oils &amp; Sauces</option><option value="olives">Olives</option><option value="pasta">Pasta</option><option value="peatnut-butter">Peatnut Butter</option><option value="rice">Rice</option>                    </select><div className="nice-select" tabIndex="0"><span className="current">Browse Category</span><ul className="list"><li data-value="" className="option selected">Browse Category</li><li data-value="soya-dairy-free" className="option">Soya &amp; Dairy Free</li><li data-value="baking-cooking" className="option">Baking &amp; Cooking</li><li data-value="butter" className="option">Butter</li><li data-value="cereals" className="option">Cereals</li><li data-value="chocolate-spread" className="option">Chocolate Spread</li><li data-value="dried-fruit" className="option">Dried Fruit</li><li data-value="honey" className="option">Honey</li><li data-value="jams" className="option">Jams</li><li data-value="jams-spreads" className="option">Jams &amp; Spreads</li><li data-value="noodles" className="option">Noodles</li><li data-value="oils-sauces" className="option">Oils &amp; Sauces</li><li data-value="olives" className="option">Olives</li><li data-value="pasta" className="option">Pasta</li><li data-value="peatnut-butter" className="option">Peatnut Butter</li><li data-value="rice" className="option">Rice</li></ul></div>
                                        </div>
                                        <div className="product-search-meta">
                                            <input type="text" name="s" value="" placeholder="Search products" />
                                            <button type="submit">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><defs><style>.cls-1{fill:none;stroke:#ababab;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><title>search</title><g><g><circle className="cls-1" cx="9" cy="9" r="8"></circle><line className="cls-1" x1="19" y1="19" x2="14.65" y2="14.65"></line></g></g></svg>
                                            </button>
                                            <input type="hidden" name="post_type" value="product" />
                                        </div>
                                    </form>
                                </div>
                                <div className="topbar-product-cart">
                                    <span className="cart-total-wrap">
                                        <span className="cart-total">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 22"><defs><style>.cls-2{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><title>shopping-bag</title><g><g><path className="cls-2" d="M4,1,1,5V19a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V5L16,1Z"></path><line className="cls-2" x1="1" y1="5" x2="19" y2="5"></line><path className="cls-2" d="M14,9A4,4,0,0,1,6,9"></path></g></g></svg>
                                            <span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">�</span>0.00</span>	</span>

                                    </span>
                                </div>
                                <div className="topbar-my-account">
                                    <a href="https://demo.cmssuperheroes.com/themeforest/raworganic/login/">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 20"><defs><style>.cls-3{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><title>your-account</title><g><g><path className="cls-3" d="M17,19V17a4,4,0,0,0-4-4H5a4,4,0,0,0-4,4v2"></path><circle className="cls-3" cx="9" cy="5" r="4"></circle></g></g></svg>
                                        <span>Your Account</span>
                                    </a>
                </div>                
                            </div>                            
                        </div>
                    </div>
                    */}
                    <div id="headroom" className="headroom headroom--top headroom--not-bottom">
                        <div className="site-header-main">
                            <div className="container">
                                <div className="row">
                                    <nav id="site-navigation" className="main-navigation">
                                        <ul id="mastmenu" className="primary-menu clearfix">
                                            <li>
                                                <div className="site-branding">
                                                    <Link className="logo" to="/" title="RawOrganic" rel="home"><img src="https://demo.cmssuperheroes.com/themeforest/raworganic/wp-content/themes/raworganic/assets/images/logo-dark.png" alt="RawOrganic" /></Link>
                                                </div>
                                            </li>
                                            <li className="menu-item menu-item-type-post_type menu-item-object-page">
                                                <Link to="/">All Recipies</Link>
                                            </li>
                                            <li className="menu-item menu-item-type-post_type menu-item-object-page">
                                                <Link to="/nutrition-search">Nutrition Search</Link>
                                            </li>
                                            {this.props.isLoggedIn && isAdmin &&
                                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-574 current-menu-item">
                                                    <Link id="navbarAdmin" to="/admin" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Admin</Link>
                                                    <div className="dropdown-menu" aria-labelledby="navbarAdmin">
                                                        <Link className="dropdown-item text-dark" to="/parser">Parser</Link>
                                                        <Link className="dropdown-item text-dark" to="/ingredient-map">Ingredient Map</Link>
                                                        <Link className="dropdown-item text-dark" to="/measurement-map">Measurement Map</Link>
                                                        <Link className="dropdown-item text-dark" to="/account/user-list">Users List</Link>
                                                    </div>
                                                </li>}
                                            {/*<li id="menu-item-574" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-574 current-menu-item"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/recipes/">Recipes</a>
                                                <ul className="sub-menu">
                                                    <li id="menu-item-1042" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1042"><a href="http://demo.cmssuperheroes.com/themeforest/raworganic/?post_type=recipe&amp;layout-recipe=grid">Recipe Grid</a></li>
                                                    <li id="menu-item-1041" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1041"><a href="http://demo.cmssuperheroes.com/themeforest/raworganic/?post_type=recipe&amp;layout-recipe=list">Recipe List</a></li>
                                                    <li id="menu-item-653" className="menu-item menu-item-type-post_type menu-item-object-recipe menu-item-653"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/recipe/banana-chocolate-peanut-butter-smoothie/">Single Recipe</a></li>
                                                </ul>
                                            </li>
                                            <li id="menu-item-107" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-107"><a href="#">Blog</a>
                                                <ul className="sub-menu">
                                                    <li id="menu-item-109" className="menu-item menu-item-type-post_type menu-item-object-page current_page_parent menu-item-109"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/blog-standard/">Blog Standard</a></li>
                                                    <li id="menu-item-1289" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1289"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/blog-grid-classic/">Blog Grid Classic</a></li>
                                                    <li id="menu-item-108" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-108"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/blog-grid-modern/">Blog Grid Modern</a></li>
                                                    <li id="menu-item-165" className="menu-item menu-item-type-post_type menu-item-object-post menu-item-165"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/the-health-boosting-benefits-of-each-nut-explained/">Blog Details</a></li>
                                                </ul>
                                            </li>
                                            <li id="menu-item-1043" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-1043"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/shop/">Shop</a>
                                                <ul className="sub-menu">
                                                    <li id="menu-item-1038" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1038"><a href="http://demo.cmssuperheroes.com/themeforest/raworganic/?post_type=product&amp;layout-shop=grid">Shop Grid</a></li>
                                                    <li id="menu-item-1039" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1039"><a href="http://demo.cmssuperheroes.com/themeforest/raworganic/?post_type=product&amp;layout-shop=list">Shop List</a></li>
                                                    <li id="menu-item-399" className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-399"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/product-category/jams-spreads/">Shop Category</a></li>
                                                    <li id="menu-item-421" className="menu-item menu-item-type-post_type menu-item-object-product menu-item-421"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/product/organic-cacao-brazil-nut-spread-raw-vegan/">Single Product</a></li>
                                                    <li id="menu-item-1384" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1384"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/cart/">Shop Cart</a></li>
                                                    <li id="menu-item-1385" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1385"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/checkout/">Shop Checkout</a></li>
                                                </ul>
                                            </li>
                                            <li id="menu-item-1185" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-1185"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/contact-2/">Contact</a>
                                                <ul className="sub-menu">
                                                    <li id="menu-item-1284" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1284"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/contact-1/">Contact 1</a></li>
                                                    <li id="menu-item-1285" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1285"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/contact-2/">Contact 2</a></li>
                                                    <li id="menu-item-1286" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1286"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/contact-3/">Contact 3</a></li>
                                                </ul>
                                            </li>*/}
                                        </ul>                        </nav>
                                    {/*<div className="site-recipe-alergies">
                                        <a href="https://demo.cmssuperheroes.com/themeforest/raworganic/recipe-alergies/vegetarian/">
                                            <i className="" style={{ backgroundColor: '#89c33b', borderColor: '#89c33b' }}>V</i>
                                            <span>Vegetarian</span>
                                        </a>
                                        <a href="https://demo.cmssuperheroes.com/themeforest/raworganic/recipe-alergies/vegan/">
                                            <i className="" style={{ backgroundColor: '#009241', borderColor: '#009241' }}>V</i>
                                            <span>Vegan</span>
                                        </a>
                                        <a href="https://demo.cmssuperheroes.com/themeforest/raworganic/recipe-alergies/organic/">
                                            <i className="" style={{ backgroundColor: '#b4733b', borderColor: '#b4733b' }}>Or</i>
                                            <span>Organic</span>
                                        </a>
                                        <a href="https://demo.cmssuperheroes.com/themeforest/raworganic/recipe-alergies/gluten-free/">
                                            <i className="flat" style={{ backgroundColor: '#ff9e2c', borderColor: '#ff9e2c' }}>GF</i>
                                            <span>Gluten-Free</span>
                                        </a>
                                    </div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="main-menu-mobile">
                        <div className="topbar-product-cart">
                            <span className="icon-cart"><i className="zmdi zmdi-shopping-cart"></i></span>
                            <div className="widget_shopping_cart">
                                <div className="widget_shopping_title">
                                    Shopping Cart 	<span className="cart-couter-items">(0 items)</span>

                                </div>
                                <div className="widget_shopping_cart_content">
                                    <ul className="cart_list product_list_widget">


                                        <li className="empty">No products in the cart.</li>


                                    </ul>{/* end product list */}
                                </div>
                            </div>

                        </div>
                        <span className="btn-nav-mobile open-menu">
                            <span></span>
                        </span>
                    </div>
                </div>
            </header>
        );
    }
}
