import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { withRouter } from 'react-router-dom';
import { range } from 'lodash';
import { fa, faSpinner, faSpin, faExclamationTriangle } from 'font-awesome/css/font-awesome.css';

class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            recipeList: [],
            isLoading: false,
            pageNumber: window.homePageNumber ? window.homePageNumber : 1,
            totalPages: 1,
            viewType: 'grid',
            byDiet: [],
            diet: 0,
            serving: 0
        };

        this._isMounted = false;

        this.handleChange = this.handleChange.bind(this);        
        //this.prev = this.prev.bind(this);
        //this.next = this.next.bind(this);
        this.moveToPage = this.moveToPage.bind(this);
        this.changeView = this.changeView.bind(this);
        this.GetHyperfinds = this.GetHyperfinds.bind(this);
        this.changeDiet = this.changeDiet.bind(this);
    }

    pageSize = 12;

    handleChange = (e) => {
        window.homePageNumber = 1;
        this.setState({
            [e.target.name]: e.target.value,
            pageNumber: 1
        }, () => {
            window.Home_RecipeList = undefined;
            this.populateRecipeData(true);
        });
    }

    handleSearchChange = (e) => {        
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            window.Home_RecipeList = undefined;
            this.populateRecipeData(true);
        });
    }

    componentDidMount() {
        this._isMounted = true;
        this.populateRecipeData();
        this.GetHyperfinds();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async GetHyperfinds() {
        const response = await fetch('/api/recipe/GetHyperfinds', {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer' // no-referrer, *client
        });
        const data = await response.json();
        this._isMounted && this.setState({ byDiet: data.results });
    }

    async populateRecipeData(refresh) {
        const pageNumber = this.state.pageNumber;
        if (!refresh && window.Home_RecipeList && window.Home_RecipeList[pageNumber]) {
            this._isMounted && this.setState({ recipeList: window.Home_RecipeList[pageNumber], isLoading: false });
            return;
        }

        this._isMounted && this.setState({ isLoading: true });
        const response = await fetch(`/api/recipe/getAllRecipies?pageNumber=${pageNumber}&pageSize=${this.pageSize}&searchText=${this.state.searchText}`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
            body: JSON.stringify({
                diet: this.state.diet,
                quickAndEasy: this.state.serving
            })
        });
        if (response.status === 200) {
            const data = await response.json();
            if (!window.Home_RecipeList)
                window.Home_RecipeList = [];

            window.Home_RecipeList[pageNumber] = data.results;
            this._isMounted && this.setState({ recipeList: data.results, isLoading: false, totalPages: data.totalPages });
        }
        this._isMounted && this.setState({ isLoading: false });
    }

    //prev() {
    //    const self = this;
    //    window.homePageNumber = this.state.pageNumber - 1;
    //    this.setState({ pageNumber: this.state.pageNumber - 1 }, () => {
    //        self.populateRecipeData();
    //    });
    //}

    //next() {
    //    const self = this;
    //    window.homePageNumber = this.state.pageNumber + 1;
    //    this.setState({ pageNumber: this.state.pageNumber + 1 }, () => {
    //        self.populateRecipeData();
    //    });

    //}

    moveToPage(pageNumber) {
    const self = this;
        window.homePageNumber = pageNumber;
        this._isMounted && this.setState({ pageNumber: pageNumber }, () => {
            self.populateRecipeData();
        });
    }

    changeView(viewType) {
        this._isMounted && this.setState({ viewType: viewType });
    }

    changeDiet(dietId) {
        const self = this;        
        this._isMounted && this.setState({ diet: dietId }, () => {
            self.populateRecipeData(true);
        });
    }

    changeServing(servingId) {
        const self = this;
        this._isMounted && this.setState({ serving: servingId }, () => {
            self.populateRecipeData(true);
        });
    }
    render() {
        const isAdmin = this.props.roles.includes("Admin");

        return (
            <div>
                <MetaTags>
                    <title>Nutrition Recipe Builder - All Recipies</title>
                    <meta id="meta-description" name="description" content="Nutrition Recipe Builder" />
                    <meta id="og-title" property="og:title" content="Nutrition Recipe Builder" />
                </MetaTags>

                <div className="content-inner">
                    <div className="container content-container">
                        <div className="row content-row">
                            <div id="primary" className="content-area content-has-sidebar float-right col-xl-9 col-lg-8 col-md-12">
                                <main id="main" className="site-main">
                                    <div className="recipe-primary">
                                        <div className="archive-banner archive-banner-top bg-image" style={{ backgroundImage: 'url(/images/recipe-banner-top.jpg)' }}>
                                            <div className="archive-inner">                                                
                                                <h2>Delicious &amp; Healthy Recipes</h2>
                                                <p>Search recipies around the web for nutritional information <Link to="/nutrition-search">here</Link></p>
                                                {!this.props.isLoggedIn &&
                                                    <p><Link to="/account/login">Login</Link> or <Link to="/account/Register">create an account</Link> to save nutritional searches</p>
                                                }
                                            </div>
                                        </div>
                                        <div className="archive-topbar">
                                            <div className="archive-topbar-heading">
                                                <h2>All Recipes</h2>
                                            </div>
                                            <div className="archive-layout">
                                                <span className={"layout-grid"  + (this.state.viewType === 'grid' ? ' active' : '')} onClick={() => { this.changeView("grid"); }}></span>
                                                <span className={"layout-list" + (this.state.viewType === 'list' ? ' active' : '')} onClick={() => { this.changeView("list"); }}></span>
                                            </div>
                                            {/*<div className="archive-topbar-ordering">
                                                <form className="archive-ordering" method="get">
                                                    <select name="orderby" className="orderby raw-archive-sort" style={{ display: 'none' }}>
                                                        <option value="newness" selected="selected">Sort by Newness</option>
                                                        <option value="oldest">Sort by Oldest</option>
                                                        <option value="name_az">Sort by Name A-Z</option>
                                                        <option value="name_za">Sort by Name Z-A</option>
                                                    </select><div className="nice-select orderby raw-archive-sort" tabindex="0"><span className="current">Sort by Newness</span><ul className="list"><li data-value="newness" className="option selected">Sort by Newness</li><li data-value="oldest" className="option">Sort by Oldest</li><li data-value="name_az" className="option">Sort by Name A-Z</li><li data-value="name_za" className="option">Sort by Name Z-A</li></ul></div>
                                                </form>
                                            </div>*/}

                                            {!this.state.isLoading && this.state.totalPages &&
                                                <nav className="navigation posts-pagination">
                                                    <div className="posts-page-links">
                                                        {range(0, this.state.totalPages).map(value => {
                                                            return (<span className={"page-numbers " + ((value + 1) === this.state.pageNumber ? "current" : "")} onClick={() => this.moveToPage(value + 1)}>{value + 1}</span>);
                                                        })}
                                                        {/*<span className="page-numbers" onClick={this.prev} disabled={this.state.pageNumber === 1}>1</span>
                                                        <span className="page-numbers" onClick={this.next} disabled={this.pageSize !== this.state.recipeList.length}>2</span>*/}
                                                    </div>
                                                </nav>
                                            }                   </div>
                                        <div className={'recipe-body row ' + (this.state.viewType === 'grid' ? ' recipe-layout-grid' : ' recipe-layout-list')}>                                                                                     
                                            {this.state.isLoading &&
                                                <p className="text-center"><i className="fa fa-spinner fa-spin"></i></p>
                                            }
                                            {!this.state.isLoading && this.state.recipeList && this.state.recipeList.map((item, index) => {
                                                if (item.label.toLowerCase().indexOf(this.state.searchText.toLowerCase()) > -1) {
                                                    return (<article key={item.id + (item.source ? item.source : '')} className="recipe-item col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                                        <div className="recipe-item-inner" style={{ height: '375px' }}>
                                                            <div className="recipe-image">
                                                                {!isAdmin && <Fragment>
                                                                    <a className="image-grid" rel="noopener noreferrer" target="_blank" href={item.source}>
                                                                        {item.image && <img width="680" height="380" src={item.image} className="attachment-raworganic-medium size-raworganic-medium wp-post-image wp-post-image" alt={item.label} rel="preconnect" />}
                                                                    </a>
                                                                    <a className="image-list bg-image" target="_blank" rel="noopener noreferrer" href={item.source} alt={item.label} style={{ backgroundImage: `url(${item.image})` }}></a></Fragment>}
                                                                {isAdmin && <Fragment>                                                                    
                                                                    <Link className="image-list bg-image" target="_blank" rel="noopener noreferrer" to={'/recipe/' + item.id} style={{ backgroundImage: `url(${item.image})` }}>
                                                                        {item.image && <img width="680" height="380" src={item.image} className="attachment-raworganic-medium size-raworganic-medium wp-post-image wp-post-image" alt={item.label} rel="preconnect" />}
                                                                    </Link></Fragment>}
                                                            </div>
                                                            <div className="recipe-holder">
                                                                {/*<div className="recipe-meal"><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/recipe-meal/desserts/" rel="tag" style={{ color: '#02d693' }}>Desserts</a></div>*/}
                                                                <h3 className="recipe-title" style={{ height: '66px' }}>
                                                                    {isAdmin && <Link to={'/recipe/' + item.id}>{item.label} {!item.active && <span className="text-warning" title="Mapping not complete."><i className="fa fa-exclamation-triangle"></i></span>}</Link>}
                                                                    {!isAdmin && <a target="_blank" rel="noopener noreferrer" href={item.source}>{item.label}</a>}
                                                                    {/*<a href="https://demo.cmssuperheroes.com/themeforest/raworganic/recipe/mixed-nut-chocolate-cake-with-smooth-peanut-butter-layer/">Mixed Nut Chocolate Cake with Smooth Peanut Butter Layer</a>*/}
                                                                </h3>
                                                                <div className="recipe-desc">
                                                                    {item.description}
                                                                </div>
                                                                <ul className="recipe-meta">
                                                                    {/*<li className="recipe-rating"><div className="raw-reviews-star"><div className="raw-stars"><span className="raw-star-show zmdi zmdi-star"></span><span className="raw-star-show zmdi zmdi-star"></span><span className="raw-star-show zmdi zmdi-star"></span><span className="raw-star-show zmdi zmdi-star"></span><span className="raw-star-show zmdi zmdi-star"></span></div></div></li>
                                                                    <li className="recipe-level"><i className="zmdi zmdi-alert-circle-o"></i>Easy</li>*/}
                                                                    {item.tag && item.tag.servings && <li><i className="zmdi zmdi-account-o"></i>Serving Size: {item.tag.servings}</li>}
                                                                    {/*<li><i className="zmdi zmdi-time"></i>50 mins</li>*/}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </article>);
                                                }
                                                else
                                                    return null;

                                            })}
                                        </div>
                                        <div className="recipe-footer">
                                            {
                                                !this.state.isLoading && this.state.totalPages &&
                                                <nav className="navigation posts-pagination">
                                                    <div className="posts-page-links">
                                                        {range(0, this.state.totalPages).map(value => {
                                                            return (<span className={"page-numbers " + ((value + 1) === this.state.pageNumber ? "current" : "")} onClick={() => this.moveToPage(value + 1)}>{value + 1}</span>);
                                                        })}
                                                        {/*<span className="page-numbers" onClick={this.prev} disabled={this.state.pageNumber === 1}>1</span>
                                                        <span className="page-numbers" onClick={this.next} disabled={this.pageSize !== this.state.recipeList.length}>2</span>*/}
                                                    </div>
                                                </nav>
                                            }
                                        </div>
                                    </div>
                                </main>{/* #main */}
                            </div>{/* #primary */}

                            <aside id="secondary" className="widget-area widget-has-sidebar sidebar-fixed col-xl-3 col-lg-4 col-md-12">
                                <div className="sidebar-sticky">
                                    <section className="widget widget_cms_recipe_attribute">
                                        <div className="widget-content">
                                            <div className="input-group">
                                                <input type="text" className="form-control" name="searchText" onChange={e => this.handleChange(e)} autoComplete="off" placeholder="Search..." />
                                            </div>
                                        </div>
                                    </section>
                                    <section id="cms_recipe_attribute-2" className="widget widget_cms_recipe_attribute"><div className="widget-content"><h2 className="widget-title">Recipes by Diet</h2>
                                        <ul className="menu">
                                        <li onClick={() => { this.changeDiet(0); }}><a href="#" className={this.state.diet === 0 ? "active" : ""}>Any Recipe</a></li>
                                        {this.state.byDiet && this.state.byDiet.map((item, index) => {
                                            return (<li key={item.name} onClick={() => {                                                
                                                this.changeDiet(item.hyperfindId);
                                            }}><a title={item.name} href="#" className={this.state.diet === item.hyperfindId ? "active" : ""}><span>{item.name}</span></a></li>);
                                        })}                                        
                                    </ul>
                                    </div></section>
                                    {/*<section id="cms_recipe_attribute-3" className="widget widget_cms_recipe_attribute"><div className="widget-content"><h2 className="widget-title">Recipes by Meal</h2>            <ul className="menu">
                                        <li><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/recipe-meal/snacks/">
                                            <span style={{ color: '#c10f84' }}>Snacks</span>
                                        </a></li>
                                        <li><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/recipe-meal/main-course/">
                                            <span style={{ color: '#e26a00' }}>Main Course</span>
                                        </a></li>
                                        <li><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/recipe-meal/drinks/">
                                            <span style={{ color: '#1253a4' }}>Drinks</span>
                                        </a></li>
                                        <li><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/recipe-meal/desserts/">
                                            <span style={{ color: '#02d693' }}>Desserts</span>
                                        </a></li>
                                        <li><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/recipe-meal/breakfast/">
                                            <span style={{ color: '#0c9cb6' }}>Breakfast</span>
                                        </a></li>
                                    </ul>
                                    </div></section><section id="cms_recipe_attribute-4" className="widget widget_cms_recipe_attribute"><div className="widget-content"><h2 className="widget-title">Recipes by Ingredients</h2>            <ul className="menu">
                                        <li><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/recipe-ingredient/vegetables/">
                                            <span>Vegetables</span>
                                        </a></li>
                                        <li><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/recipe-ingredient/meat/">
                                            <span>Meat</span>
                                        </a></li>
                                        <li><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/recipe-ingredient/grains-pulses/">
                                            <span>Grains &amp; Pulses</span>
                                        </a></li>
                                        <li><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/recipe-ingredient/fruit/">
                                            <span>Fruit</span>
                                        </a></li>
                                        <li><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/recipe-ingredient/fish/">
                                            <span>Fish</span>
                                        </a></li>
                                        <li><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/recipe-ingredient/dairy/">
                                            <span>Dairy</span>
                                        </a></li>
                                        <li><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/recipe-ingredient/chocolate/">
                                            <span>Chocolate</span>
                                        </a></li>
                                    </ul>
                                    </div>
                                    </section>
                                    */}
                                    <section id="cms_recipe_attribute-5" className="widget widget_cms_recipe_attribute"><div className="widget-content"><h2 className="widget-title">Quick &amp; Easy</h2>            <ul className="menu">
                                        {/*<li><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/recipe-time/under-15-minutes/">
                                            <span>Under 15 Minutes</span>
                                        </a></li>
                                        <li><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/recipe-time/over-15-minutes/">
                                            <span>Over 15 Minutes</span>
                                        </a></li>
                                        <li><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/recipe-time/easy-recipes/">
                                            <span>Easy Recipes</span>
                                        </a></li>
                                        <li><a href="https://demo.cmssuperheroes.com/themeforest/raworganic/recipe-time/best-rated/">
                                            <span>Best Rated</span>
                                        </a></li>*/}
                                        <li onClick={() => { this.changeServing(0); }}><a href="#" className={this.state.serving === 0 ? "active" : ""}>
                                            <span>Any Recipe</span>
                                        </a></li>
                                        <li onClick={() => { this.changeServing(1); }}><a href="#" className={this.state.serving === 1 ? "active" : ""}>
                                            <span>&lt; 5 Servings</span>
                                        </a></li>
                                        <li onClick={() => { this.changeServing(2); }}><a href="#" className={this.state.serving === 2 ? "active" : ""}>
                                            <span>&gt; 5 Servings</span>
                                        </a></li>
                                    </ul>
                                    </div></section>
                                </div>
                            </aside>

                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="archive-banner archive-banner-bottom bg-image" style={{ backgroundImage: 'url(https://demo.cmssuperheroes.com/themeforest/raworganic/wp-content/uploads/2018/09/recipe-banner-bottom.jpg)' }}>
                                    <div className="archive-inner">
                                        <h2>Get the latest recipes to your inbox</h2>
                    Sign up for our mailing list below to get all the latest recipies and new information</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Home);